.product-reas{
    padding: 12px;
    margin-bottom: 12px;
    .pr-item{
        a{                                            
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 5px;
            }
            .pr-text{
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                color: $tertiary;
                line-height: 18px;
                letter-spacing: 0.03em;
            }
        }
    }
    .payment-method-logo-block {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        margin-top: 20px;
        .payement-logo {
            height: 100%;
            padding: 4px;
            border: 1px solid #ddd;
            border-radius: 6px;
            background-color: #fff;
            display: inline-block;
            width: auto;
            img {
                width: 48px;
                height: 30px;
            }
        }
    }
}