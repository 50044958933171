.customer-layout-1 {
    .breadcrumb {
        @include media-breakpoint-up(lg){
            background-color: $paper;
        }
    }
    #wrapper {
        @include media-breakpoint-up(lg){
            background-color: $paper;
        }
        @include media-breakpoint-down(lg){
            padding: 0 30px;
        }
        .container{
            padding: 0;
        }
        #content-wrapper {
            background-color: #FFF;
            @include media-breakpoint-up(lg){
                background-color: $paper;
            }
            .container {
                padding: 0;
                border: none;
                background-color: #FFF;
                &#main{
                    padding: 0;
                }
                @include media-breakpoint-up(lg){
                    background-color: $paper;
                }
                .page-header {
                    border: none;
                    background: none;
                    padding-top: 0;
                    padding-bottom: 0;
                    h1 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
                .page-content {
                    padding: 0;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 55px;
                        padding: 40px 0 0;
                    }
                    .os-subtitle{
                        font-family: 'Prompt';
                        font-size: 13px;
                        color: #000;
                        margin-bottom: 0;
                        background: #fff;
                        text-align: center;
                        padding: 30px 15px;
                        &.have-list{
                            padding: 20px 15px;
                        }
                        @include media-breakpoint-up(lg){
                            font-size: 20px;
                            padding: 46px 15px;
                        }
                    }
                    .notifications-container{
                        .alert{
                            &.alert-warning{                                
                                border: none;
                                background-color: rgba(255, 0, 0, 0.1);
                                ul{
                                    li{                                        
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        svg{
                                            width: 20px;
                                            margin-right: 10px;
                                            @include media-breakpoint-up(lg){
                                                width: 24px;
                                                margin-right: 20px;
                                            }
                                        }
                                        span{
                                            font-family: 'Prompt';
                                            font-size: 12px;
                                            color: #D62836;
                                            @include media-breakpoint-up(lg){
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}