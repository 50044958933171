.page-cms {
    .page-content,
    .click-and-collect,
    .ddw-wrapper {
        p,
        ul li {
            @include media-breakpoint-up(sm){
                font-size: 15px;
                line-height: 17px;
            }
            @include media-breakpoint-up(xxl){
                font-size: 18px;
                line-height: 21px;
            }
        }
    }
}
.page-cms {
    .ddw-wrapper {
        .img-schedule {
            @include media-breakpoint-up(xl){
                margin-top: 55px;
            }
            >.img {
                @include media-breakpoint-up(xl){
                    max-width: 50%;
                    width: 50%;
                    min-width: 50%;
                }
                .image {
                    min-height: 240px;
                    min-width: 100%;
                    max-width: 100%;
                    background-repeat: no-repeat!important;
                    background-position: center!important;
                    background-size: cover!important;
                    margin-top: 20px;
                    margin-bottom: 25px;
                    @include media-breakpoint-up(sm){
                        min-height: 410px;
                    }
                    @include media-breakpoint-up(lg){
                        min-height: 500px;
                        min-width: 570px;
                        max-width: 570px;
                        margin: auto;
                        margin-top: 20px;
                        margin-bottom: 25px;
                    }
                    @include media-breakpoint-up(xl){
                        margin: 0;
                        height: 100%;
                        min-height: 680px;
                        max-height: 680px;
                        min-width: 100%;
                    }
                }
            }
            >.schedule {
                padding-top: 15px;
                margin-bottom: 20px;
                @include media-breakpoint-up(xl){
                    max-width: 50%;
                    width: 50%;
                    margin-bottom: 0;
                    background-color: transparent!important;
                    padding-top: 0;
                    padding-left: 25px;
                }
                .time-slot {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-top: 1px solid #9E9E9E;
                    display: none;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        display: flex;
                    }
                    @include media-breakpoint-up(xl){
                        background-color: $paper;
                        padding-inline: 25px;
                    }
                    &:first-child {
                        border-top: none;
                    }
                    * {
                        letter-spacing: 0.03em;
                        color: #000;
                        font-size: 15px;
                        line-height: 18px;
                        b {
                            font-weight: 700;
                        }
                    }
                    .date {
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                        letter-spacing: 0.03em;
                        color: #000;
                    }
                    .place-name {
                        font-weight: 600;
                    }
                }   
            }
            .no-timeslot-message {
                @include media-breakpoint-up(xl){
                    max-width: 50%;
                    width: 50%;
                    min-width: 50%;
                }
                p {
                    font-weight: 600;
                }
            }
        }
    }
    .click-and-collect-wrapper {
        @include media-breakpoint-up(xxl){
            background-color: $paper;
            .pink-stain {
                right: 15px;
                top: 0;
                max-height: 90px;
            }
            .orange-stain {
                right: 93px;
                bottom: 18px;
                max-height: 130px;
            }
        }
        .click-and-collect {
            margin-top: 55px;
            padding-top: 20px;
            padding-bottom: 30px;
            @include media-breakpoint-up(xl){
                margin-top: 120px;
            }
            @include media-breakpoint-up(xxl){
                position: unset!important;
                padding-top: 60px;
                padding-bottom: 45px;
            }
            .leaf {
                right: 0;
                max-height: 85px;
                transform: rotateY(180deg);
                @include media-breakpoint-up(lg){
                    max-height: 135px;
                }
                @include media-breakpoint-up(xxl){
                    transform: rotateY(0deg);
                    max-height: 200px;
                    left: 0;
                    top: 60px;
                }
            }
            .rainbow {
                left: 4px;
                bottom: 100px;
                max-height: 55px;
                @include media-breakpoint-up(lg){
                    max-height: 70px;
                    bottom: 50px;
                }
                @include media-breakpoint-up(xxl){
                    display: none;
                }
            }
            .title {
                font-family: 'Prompt';
                font-size: 19px;
                line-height: 23px;
                letter-spacing: 0.03em;
                color: #000;
                margin-top: 0;
                padding-right: 20px;
                text-align: left;
                @include media-breakpoint-up(lg){
                    font-size: 28px;
                    line-height: 30px;
                }
            }
            .subtitle {
                letter-spacing: 0.03em;
                margin-top: 10px;
                padding-right: 20px;
            }
            .qa-wrapper {
                margin-top: 25px;
                @include media-breakpoint-up(xxl){
                    margin-top: 40px;
                }
                .qa {
                    .colored-pipe {
                        min-width: 7px;
                        max-width: 7px;
                        min-height: 26px;
                        max-height: 26px;
                        margin-right: 18px;
                        @include media-breakpoint-up(lg){
                            min-height: 42px;
                            max-height: 42px;
                            margin-top: -8px;
                        }
                    }
                    .question {
                        margin: 0;
                        font-family: 'Prompt';
                        font-weight: 500;
                        letter-spacing: 0.03em;
                        color: #000;
                        line-height: 26px;
                        @include media-breakpoint-up(lg){
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    .answer {
                        p {
                            margin-top: 0px;
                            margin-bottom: 10px;
                            &:first-child {
                                margin-top: 5px;
                            }
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
            .btns {
                .btn {
                    max-height: 48px;
                    &:hover {
                        background-color: $yellow!important;
                        opacity: 0.5;
                    }
                    @include media-breakpoint-up(xxl){
                        max-height: 58px;
                        min-height: 58px;
                    }
                    &:first-child {
                        opacity: 0.5;
                        margin-bottom: 15px;
                        &:hover {
                            background-color: $yellow!important;
                            opacity: 1;
                        }
                        @include media-breakpoint-up(lg){
                            margin-bottom: 0;
                            margin-right: 18px;
                        }
                    }
                }
            }
        }
    }
}