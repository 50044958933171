$colors: "tertiary" $tertiary, "tertiary-light" $tertiary-light, "yellow" $yellow, "secondary" $secondary, "pink" $pink, "paper" $paper;

/* BUTTONS */
button, .btn {
    display: flex;
    border: none;
    width: fit-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    background-color: $tertiary;
    padding: 15px 40px;
    transition: all 0.2s ease-in-out;
    @include media-breakpoint-up(sm) {
        font-size: 18px;
        line-height: 21px;
    }
    &:hover {
        color: #fff;
        background-color: $tertiary-light!important;
    }
    &:focus,
    &.focus {
        box-shadow: none;
    }
}

@each $name, $color in $colors {  
    .btn-#{$name} { 
        background-color: #{$color}!important;
        color: $paper;
        border: 2px solid #{$color};
    }
}
.btn-tertiary-light:hover {
    background-color: $tertiary!important;
    border: 2px solid $tertiary;
}
.btn-yellow {
    border: none;
}
.btn-secondary:hover {
    background-color: $yellow!important;
    border: 2px solid $yellow;
}

.btn-light {
    background-color: $tertiary-light;
    border: 2px solid $tertiary-light;
    &:hover{
        background-color: $tertiary !important;
        border: 2px solid $tertiary;
    }
}
    
/* IMAGES */
img {
    display: inline-block;
    font-size: 15px;
    line-height: 18px;
    max-width: 100%;
    height: auto;
}

/* TITLES */    
h1,
.h1 {
    text-transform: none!important;
    font-family: Prompt;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 0px;
    @include media-breakpoint-up(lg){
        font-size: 28px;
        line-height: 30px;
    } 
}
h2,
.h2 {
    font-family: 'Prompt';
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #000;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
        font-size: 28px;
        line-height: 32px;
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: 30px;
    }
}

h3,
.h3 {
    margin-bottom: 25px;
    font-family: 'Prompt';
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: black;
    text-transform: initial;
    text-align: left;
    @include media-breakpoint-up(lg){
        font-size: 24px;
        line-height: 28px;
    }
    @include media-breakpoint-up(xxl){
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 35px;
    }
}

/* TEXTS */
.page-header {
    padding: 0;
    border: none;
    background-color: transparent;
}
b, strong{
    font-weight: bold;
}
p,
ul li {
    font-size: 13px;
    line-height: 15px;
    text-align: justify;
    letter-spacing: 0.02em;
    margin-top: 20px;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
        font-size: 20px;
        line-height: 22px;
    }
    @include media-breakpoint-up(lg) {
        line-height: 24px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }
}
ul li {
    margin-top: 5px;
}
.page-cms ul {
    padding-left: 30px!important;
    padding-top: 10px;
}

/* CONTAINERS */
.container {
    width: 100%;
    max-width: 100%;
    padding-inline: 30px;
    @include media-breakpoint-up(sm) {
      padding-inline: 60px;
    }
    @include media-breakpoint-up(xxl) {
        padding-inline: 0;
        max-width: 1320px;
    }
}
.xl-container {
    @include media-breakpoint-up(xl) {
        width: 100%;
        max-width: 100%;
        padding-inline: 60px;
        @include media-breakpoint-up(xxl){
            padding-inline: 0;
            max-width: 1320px;
        }
    }
}

/* BACKGROUND COLORS */
@each $name, $color in $colors {  
    .bg-#{$name} { 
        background-color: #{$color}!important;
    }
}

/* CHECKBOX AND RADIO BUTTON */
.custom-control{
    *{
        cursor: pointer;
    }
    .custom-control-label{
        &::before{
            top: -4px;
            width: 22px;
            height: 22px;
            border: none;
            background-color: $gray-bgcheck;
        }
        &::after{
            top: 1px;
            left: -19px;
            width: 12px;
            height: 12px;
        }
    }
    &.custom-radio{
        padding-left: 35px;
        .custom-control-label{
            &::before{
                left: -35px;
            }
            &::after{
                left: -30px;
                border-radius: 50%;
            }
        }
    }
    &.custom-checkbox{
        padding-left: 37px;
        margin-bottom: 5px;
        .custom-control-label{
            &::before{
                left: -37px;
            }
            &::after{
                left: -32px;
            }
        }
    }
    .custom-control-input{
        &:checked{
            ~.custom-control-label{
                &::before{
                    background-color: $gray-bgcheck;
                }
                &::after{
                    background: $gray-bgchecked;
                }
            }
        }
        &:focus{
            ~.custom-control-label{
                &::before{
                    box-shadow: none;
                }
            }
        }
    }
}

/* END CHECKBOX AND RADIO BUTTON */

/* SELECT FIELDS */
.form-group{
    .custom-select{
        border: none;
        box-shadow: none;
        background: $gray-bgfield url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 12L0.00480941 0.75L12.9952 0.750001L6.5 12Z' fill='black'/%3E%3C/svg%3E") no-repeat right .75rem center;
    }
}
/* END SELECT FIELDS */


/* ERROR MESSAGE ON FIELDS */
.invalid-feedback * {
    font-size: .875rem;
    color: #dc3545;
}
/* END ERROR MESSAGE ON FIELDS */


/* ALERT WARNING */
.alert{
    &.alert-warning{        
        display: flex;
        padding: 12px 15px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background-color: rgba(255,0,0,.1);
        svg{
            width: 20px;
            margin-right: 10px;
            @include media-breakpoint-up(lg){
                width: 24px;
                margin-right: 20px;
            }
        }
        span{
            font-family: 'Prompt';
            font-size: 13px;
            color: #d62836;
        }
    }
}
/* END ALERT WARNING */