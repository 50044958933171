.page-cart {
    .container{
        padding: 0 15px;
    }
    nav{
        .breadcrumb{
            padding: 32px 0;
            margin-bottom: 0;
            *{
                font-family: 'Prompt';
                font-size: 16px;
                color: #000;
                @include media-breakpoint-up(lg){
                    font-family: 'Raleway';
                }
            }
        }
    }
    .row{
        #content-wrapper{
            #main{
                .page-header{
                    margin-bottom: 5px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 38px;
                    }
                    h1{
                        margin-top: 0;
                    }
                }
                .container{
                    .cart-grid{
                        margin-bottom: 19px;
                        @include media-breakpoint-up(sm){
                            max-width: 640px;
                            margin: auto;
                        }
                        @include media-breakpoint-up(lg){
                            max-width: inherit;
                            margin: 0 -15px;
                        }
                        .cart-grid-body{
                            .cart-container {
                                border: 0;
                                .card-header {
                                    background: none;
                                    border: none;
                                    padding: 0;
                                    margin-top: 35px;
                                }
                                .card-body {
                                    background: $paper;
                                    padding: 16px 16px 0 16px;
                                    margin-top: 10px;
                                    @include media-breakpoint-up(lg){
                                        margin-top: 0;
                                        padding: 25px 40px;
                                    }
                                    .cart-overview {
                                        .cart-overview-title {
                                            font-size: 16px;
                                            line-height: 19px;
                                            margin-bottom: 0;
                                            border-bottom: 1px solid #000;
                                            margin-bottom: 18px;
                                            padding-bottom: 5px;
                                            .cart-thead{
                                                @include media-breakpoint-up(lg){
                                                    float: right;
                                                    padding: 0;
                                                    display: flex;
                                                }
                                                span{
                                                    width: 165px;
                                                    font-size: 18px;
                                                    font-weight: 400;
                                                    text-align: center;
                                                    display: inline-block;
                                                    &:first-child{
                                                        flex: 1;
                                                    }
                                                    &:last-child{
                                                        width: 50px;
                                                        display: flex;
                                                        justify-content: flex-end;
                                                    }
                                                    @include media-breakpoint-down(xl){
                                                        font-size: 14px;
                                                    }
                                                }
                                            }
                                        }
                                        .no-items{
                                            font-family: 'Prompt';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 11px;
                                            letter-spacing: 0.03em;
                                            text-transform: uppercase;
                                            padding: 17px 15px;
                                            margin-bottom: 18px;
                                            display: block;
                                            text-align: center;                                            
                                            color: #D62836;
                                            background-color: rgba(255, 0, 0, 0.1);
                                            @include media-breakpoint-up(lg){
                                                font-size: 15px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            }
                                            svg{
                                                margin-right: 15px;
                                            }
                                        }
                                        .cart-items {
                                            .cart-item {
                                                margin-bottom: 20px;
                                                padding-bottom: 20px;
                                                .product-line-grid {
                                                    .media {
                                                        img {
                                                            width: 95px;
                                                            min-width: 95px;
                                                            margin-right: 17px;
                                                        }
                                                        .media-body {
                                                            padding: 0;
                                                            .product-line__title {
                                                                font-family: 'Raleway';
                                                                font-size: 14px;
                                                                font-weight: 600;
                                                                color: #000000;
                                                                line-height: 16px;
                                                                margin-bottom: 4px;
                                                                letter-spacing: 0.03em; 
                                                            }
                                                            .produt-line__desc{
                                                                font-size: 12.5px;
                                                                line-height: 14.67px;
                                                                margin-bottom: 9px;
                                                                *{
                                                                    font-size: 12.5px;
                                                                    line-height: 14.67px;
                                                                    margin-top: 0;
                                                                    margin-bottom: 0;
                                                                }
                                                            }
                                                            .product-line__price{
                                                                .product-price{
                                                                    font-family: 'Raleway';
                                                                    font-style: normal;
                                                                    font-weight: 500;
                                                                    font-size: 13px;
                                                                    color: #000;
                                                                    line-height: 15px;
                                                                    letter-spacing: 0.03em;
                                                                }
                                                            }
                                                            .product-line-info {
                                                                .current-price {
                                                                    .price {
                                                                        font-weight: 500;
                                                                        letter-spacing: 0.03em;
                                                                        color: #000000;
                                                                    }
                                                                }
                                                            }
                                                            .cart-line-product-actions{
                                                                .remove-from-cart{
                                                                    span{
                                                                        font-size: 12px;
                                                                        color: $secondary;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .product-line-grid-right{
                                                        justify-content: flex-start;
                                                        .cart-line-product-actions{
                                                            width: 95px;
                                                            margin-right: 17px;
                                                            a{
                                                                font-size: 12px;
                                                                svg{
                                                                    transform: translateY(-1px);
                                                                    circle,
                                                                    path{
                                                                        stroke: #000;
                                                                    }
                                                                }
                                                                span{
                                                                    color: #000;
                                                                }
                                                            }
                                                        }
                                                        .qty-field{
                                                            flex: 1;
                                                            .input-group{
                                                                border: none;
                                                                width: 100%;
                                                                max-width: 210px;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                background-color: #E3E3E3;
                                                                @include media-breakpoint-up(lg){
                                                                    max-width: 60px;
                                                                    margin: auto;
                                                                    background-color: transparent;
                                                                }
                                                                .input-group-btn{
                                                                    .btn{
                                                                        padding: 0 15px;
                                                                        background-color: transparent;
                                                                        @include media-breakpoint-up(lg){
                                                                            padding: 0 5px;
                                                                        }
                                                                    }
                                                                }
                                                                .form-control{
                                                                    background-color: transparent;
                                                                    @include media-breakpoint-up(lg){
                                                                        font-size: 16px;
                                                                        font-weight: 500;
                                                                        padding: 0;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .cart-grid-right{
                            .cart-summary{
                                border: none;
                                background-color: $paper;                                
                                @include media-breakpoint-up(lg){
                                    margin-bottom: 24px;
                                }
                                .card-body{
                                    padding-top: 0;
                                    .cart-detailed-totals{
                                        .cart-summary-line{
                                            padding: 17px 0;
                                            background-color: transparent;
                                            border-bottom: 1px solid #000;
                                            *{
                                                font-family: 'Raleway';
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                display: flex;
                                                align-items: center;
                                                text-align: justify;
                                                letter-spacing: 0.03em;
                                                color: #000000;
                                            }
                                            &.cart-qty{
                                                .label{
                                                    font-family: 'Raleway';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 15px;
                                                    line-height: 18px;
                                                    color: #000000;
                                                    display: flex;
                                                    align-items: center;
                                                    text-align: justify;
                                                    letter-spacing: 0.03em;
                                                }
                                            }
                                            &.promo-code-button{
                                                .collapse-button{
                                                    .redux-icon{
                                                        transition: transform ease-in-out 300ms;
                                                        .redux-add{
                                                            font-size: 13px;
                                                            margin-right: 8px;
                                                            display: inline-block;
                                                        }
                                                    }
                                                    &[aria-expanded="true"]{
                                                        .redux-icon{
                                                            svg{
                                                                transform: rotate(45deg);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &.cart-total{
                                                border-bottom: none;
                                                *{
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                        .consigne-bloc{
                                            @include media-breakpoint-up(lg){
                                                display: none;
                                            }
                                            @include media-breakpoint-down(lg){
                                                display: block;
                                            }
                                            .consigne{
                                                .picto{
                                                    transition: transform ease-in-out 300ms;
                                                }
                                                &.open{
                                                    .picto{
                                                        transform: rotate(45deg);
                                                    }
                                                }
                                            }
                                            .consigne-desc{
                                                display: none;
                                                p{
                                                    font-size: 13px;
                                                    line-height: 18px;
                                                    margin: 15px 0;
                                                }
                                                .knowmore{
                                                    font-size: 13px;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                        
                                        .promo-code{
                                            .promo-code__content{
                                                padding-bottom: 0;
                                                background-color: transparent;
                                                .form__add-voucher{
                                                    .input-group{
                                                        .input-group-append{
                                                            .btn{
                                                                padding: 7px 15px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .card-footer{
                                    border: none;
                                    background-color: transparent;
                                    .checkout{
                                        padding: 0;
                                        position: static;
                                        .text-sm-center{
                                            a{
                                                padding: 10px 15px;
                                                justify-content: center;
                                                @include media-breakpoint-up(lg){
                                                    width: 100%;
                                                    padding: 18px 15px;
                                                }
                                            }
                                        }
                                        .btn{
                                            width: 100%;
                                            display: block;
                                        }
                                    }
                                }                                
                            }
                            .consigne-bloc{
                                @include media-breakpoint-up(lg){
                                    display: block;
                                }
                                @include media-breakpoint-down(lg){
                                    display: none;
                                }
                                .consigne-title{
                                    font-weight: 600;
                                    font-size: 17px;
                                    line-height: 20px;
                                    text-align: justify;
                                    letter-spacing: 0.03em;
                                    color: #000000;
                                    margin-bottom: 8px;
                                }
                                .consigne-desc{
                                    p{
                                        font-weight: 400;
                                        font-size: 15px;
                                        line-height: 18px;
                                        text-align: justify;
                                        letter-spacing: 0.03em;
                                        color: #4F4F4F;
                                        margin-top: 0;
                                        margin-bottom: 15px;
                                    }
                                }
                                .knowmore{
                                    font-weight: 400;
                                    font-size: 15px;
                                    color: #000000;
                                    line-height: 18px;
                                    text-align: justify;
                                    letter-spacing: 0.03em;
                                    text-decoration-line: underline;
                                }                                
                            }
                        }
                    }
                    .points-retrait{
                        @include media-breakpoint-down(lg){
                            display: none;
                        }
                        @include media-breakpoint-up(lg){
                            display: block;
                        }
                        &.visible--desktop{
                            padding-top: 20px;
                            border-top: 1px solid #000;
                        }                        
                        .pr-container{                            
                            .pr-content{
                                display: flex;
                                align-items: center;
                                margin-bottom: 28px;
                                @include media-breakpoint-down(sm){
                                    display: block;
                                }
                                img{
                                    @include media-breakpoint-down(sm){
                                        display: block;
                                        margin: 0 auto 25px;
                                    }
                                }
                                .pr-text{
                                    padding-left: 7px;
                                    @include media-breakpoint-up(lg){
                                        padding-left: 15px;               
                                    }
                                    p{
                                        font-size: 14px;
                                        color: #000;
                                        font-style: normal;
                                        line-height: 18px;
                                        letter-spacing: 0.03em;
                                        text-align: left;
                                        margin-top: 0;
                                        @include media-breakpoint-up(lg){
                                            font-size: 15px;
                                        }
                                        strong{
                                            display: block;
                                        }
                                    }
                                }                                
                            }
                            .pr-button{
                                a{
                                    font-weight: 600;
                                    font-size: 18px;
                                    display: block;
                                    color: #FFFFFF;
                                    height: 58px;
                                    line-height: 58px;
                                    text-align: center;
                                    transition: background-color ease-in-out 300ms;
                                    background-color: $tertiary-light;
                                    &:hover{
                                        background-color: $tertiary !important;
                                    }
                                }
                            }
                        }
                    }
                    > .points-retrait{
                        @include media-breakpoint-up(lg){
                            display: none;
                        }
                        @include media-breakpoint-down(lg){
                            display: block;
                        }
                    }
                }
            }
        }
    }
    
}