#blockcart-modal {
    z-index: 1070;
    @include media-breakpoint-up(lg){
        position: absolute;
        width: 100%;
        height: fit-content;
        top: 170px;
    }
    .modal-dialog {
        margin: 0;
        max-width: 100%;
        padding-inline: 0;
        .modal-content {
            border: none;
            box-shadow: none;
            @include media-breakpoint-down(md){
                >div {
                    border: none!important;
                }
            }
            @include media-breakpoint-up(lg){
                right: -10px;
                align-items: flex-end;
                background: none;
            }
            .modal-header {
                border: none;
                padding: 0;
                @include media-breakpoint-up(lg){
                    max-width: 400px;
                    background-color: white;
                }
                .modal-title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 12px;
                    letter-spacing: 0.05em;
                    svg {
                        margin-right: 10px;
                    }
                }
                .close {
                    opacity: 1;
                    padding: 0;
                    margin: 0;
                }
            }
            .modal-body {
                margin-bottom: 35px;
                padding: 0;
                @include media-breakpoint-up(lg){
                    max-width: 400px;
                    background-color: white;
                }
                .product-image {
                    width: 100%;
                    max-width: 35%;
                    margin-right: 0;
                }
                .media-body {
                    padding-left: 15px;
                    padding-right: 20px;
                }
                p,
                span {
                    font-family: 'Raleway';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.05em;
                    color: #000000;
                    text-align: left;
                    margin: 0;
                   
                }
            }
            .modal-footer {
                box-shadow: none;
                border: none;
                position: relative;
                @include media-breakpoint-up(lg){
                    max-width: 400px;
                    text-align: center;
                    background-color: white;
                }
                .continue-shopping {
                    font-size: 15px;
                    line-height: 20px;
                    text-decoration-line: underline;
                    color: #000000;
                    margin-inline: 0;
                    margin-top: 20px;
                    margin-bottom: 30px;
                    @include media-breakpoint-up(lg){
                        order: 2;
                        width: 100%;
                    }
                }
                .checkout-btn {
                    font-size: 15px;
                    line-height: 20px;
                    font-family: 'Raleway';
                    justify-content: center;
                    @include media-breakpoint-up(lg){
                        width: 100%;
                        order: 1;
                    }
                }
            }
        }
    }
}