.page-addresses {
    #wrapper {
        padding-inline: 0;
    }
    @include media-breakpoint-up(lg){
        #wrapper {
            background-color: $paper;
            .breadcrumb {
                background-color: $paper;
            }
        }
    }
    .page-wrapper {
        border: none;
        @include media-breakpoint-up(lg){
            background-color: $paper;
        }
        .page-header {
            border: none;
            background: none;
            padding: 0;
            margin-bottom: 30px;
        }
        .page-content {
            padding: 0;
            @include media-breakpoint-up(lg) {
                min-width: 820px;
                max-width: 850px;
                margin: auto;
                padding: 30px 35px 60px 35px;
                background-color: #FFF;
                margin-bottom: 90px;
                .desktop-title {
                    font-family: 'Prompt';
                    letter-spacing: 0.03em;
                    color: #000;
                    margin-bottom: 30px;
                    margin-top: 0;
                }
            }
            .addresses {
                &:not(:first-child) {
                    .address {
                        .desktop-title {
                            margin-top: 10px;
                        }
                    }
                }
                .address {
                    border: none;
                    .address-body {
                        padding: 0;
                        address {
                            font-size: 13px;
                            line-height: 15px;
                            letter-spacing: 0.03em;
                            color: #000;
                            margin-bottom: 25px;
                            border: none;
                            @include media-breakpoint-up(lg){
                                font-size: 15px;
                                line-height: 18px;
                            }
                        }
                    }
                }
                .address-footer {
                    background: none;
                    padding: 0;
                    padding-bottom: 25px;
                    border-top: none;
                    border-bottom: 0.5px solid #000;
                    margin-bottom: 25px;
                    font-size: 13px;
                    letter-spacing: 0.03em;
                    color: #434343;
                    justify-content: flex-start;
                    a {
                        &:first-child {
                            margin-right: 70px;
                        }
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .addresses-footer {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: #434343;
                font-weight: 600;
                span {
                    text-decoration: underline;
                    margin-right: 20px;
                }
            }
        }
    }
    #footer {
        margin-top: 110px;
        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }
}