.featured-products {
    margin-top: 76px;
    margin-bottom: 76px;
    >.container {
        padding-right: 0;
        .swiper-navigation {
            width: 100%;
            justify-content: space-between;
            position: absolute;
            top: 33%;
            left: 0;
            padding-inline: 2.5%;
            .swiper-button {
                position: relative;
                &-prev {
                    right: -27px;
                    @include media-breakpoint-up(xxl){
                        right: 9px;
                    }
                }
                &-next {
                    left: -27px;
                    @include media-breakpoint-up(xxl){
                        left: 9px;
                    }
                }
                &-disabled {
                    svg path {
                        stroke: #0000004c;
                    }
                }
            }
            @include media-breakpoint-up(xl){
                padding-inline: 1.5%;
                top: 36%;
            }
            @include media-breakpoint-up(xl){
                padding-inline: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-right: 60px;
            position: relative;
        }
        @include media-breakpoint-up(xxl) {
            padding-right: 0px;
        }
    }
    .all-product-link {
        margin-top: 30px;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(lg){
        margin-top: 95px;
        margin-bottom: 110px;
    }
}

.products-swiper,
.featured-products-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: -11px;
    .swiper-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            min-width: 35%;
            margin-inline: 11px;
            .product-miniature {
                max-width: 100%;
                min-width: 100%;
                padding: 0;
                .card-product {
                    border: none;
                    .product-flags {
                        display: none;
                    }
                    .card-body {
                        padding: 0;
                        .product-description {
                            .product-title {
                                margin-top: 5px;
                                margin-bottom: 5px;
                                font-family: 'Prompt';
                                font-weight: 400;
                                font-size: 12.5px;
                                line-height: 13px;
                                color: $primary;
                                text-align: left;
                                @include media-breakpoint-up(sm){
                                    font-size: 15px;
                                    line-height: 15.5px;
                                } 
                                @include media-breakpoint-up(xl){
                                    font-size: 18px;
                                    line-height: 22px;
                                }                        
                            }
                            .product-price-and-shipping {
                                line-height: 1;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                .price {
                                    font-weight: 700;
                                    font-size: 12px;
                                    line-height: 10px;
                                    @include media-breakpoint-up(sm){
                                        font-size: 14.5px;
                                        line-height: 12.5px;
                                    } 
                                    @include media-breakpoint-up(xl){
                                        font-size: 15px;
                                        line-height: 20px;
                                    }  
                                }
                            }
                        }
                    }
                    .card-img-top {
                        img {
                            width: 100%;
                            // min-width: 125px;
                        }
                    }
                }
            }
            @include media-breakpoint-up(lg){
                min-width: calc(25% - 35px);
                margin-inline: 17.5px;
            }
        }
    }
    @include media-breakpoint-up(lg){
        margin-left: 0;
        .swiper-navigation {
            position: absolute;
            top: 24%;
            width: 100%;
            justify-content: space-between;
            .swiper-button-disabled {
                opacity: 0.35;
            }
        }
    }
    @include media-breakpoint-up(xl){
        .swiper-navigation {
            top: 27%;
        }
    }
    @include media-breakpoint-up(xxl){
        .swiper-navigation {
            top: 31%;
        }
    }
}