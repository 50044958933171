#footer {
  .footer-container {
    background-color: $paper;
    padding-top: 25px;
    @include media-breakpoint-up(lg){
      padding-top: 50px;
      padding-bottom: 35px;
    }
    .logo-row {
      width: 100%;
      @include media-breakpoint-up(lg){
        width: fit-content;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
        padding-left: 10px;
        padding-right: 10px;
        margin-inline: 0;
      }
    }
    .logo {
      max-width: 110px;
      @include media-breakpoint-up(lg){
        max-width: 100%;
      }
      @include media-breakpoint-up(xl){
        max-width: 158px;
      }
    }
    .blocklink {
      padding-left: 10px;
      padding-right: 10px;
      ul {
        padding-top: 0;
        padding-left: 0!important;
        li {
          text-align: left;
          a {
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: #000000;
            @include media-breakpoint-up(lg){
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
      &.contact-newsletter {
        @include media-breakpoint-down(md){
          .blocklink {
            .wrapper {
              width: 50%;
              padding-right: 20px;
            }
          }
        }
        @include media-breakpoint-up(lg){
          max-width: 367px;
        }
      }
    }
    .blocklink .block-title,
    .social-medias .footer__title,
    .block_newsletter #block-newsletter-label {
      font-family: 'Prompt';
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.05em;
      margin-bottom: 15px;
      @include media-breakpoint-up(lg){
        color: $secondary;
        font-size: 23px;
        line-height: 28px;
      }
    }
    .blocklink:nth-child(2),
    .blocklink:nth-child(3) {
      .block-title {
        min-height: 50px;
        @media screen and (min-width: 423.9999px) {
          min-height: unset;
        }
      }
    }
    .social-medias {
      .footer__title {
        color: #4F4F4F;
        text-transform: none;
        text-align: left;
        font-size: 13px;
        line-height: 16px;
        @include media-breakpoint-up(lg){
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
      .social-media-btn {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .block_newsletter {
      .input-group {
        input {
          height: auto;
          box-shadow: none;
          border: none;
          font-size: 13px;
          line-height: 28px;
        }
        button {
          max-width: 155px;
          padding: 10px 15px;
          span {
            font-weight: 700;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }
      #gdpr_consent {
        margin-top: 10px;
        .custom-control-label {
          font-size: 11px;
          line-height: 13px;
          text-align: justify;
          letter-spacing: 0.03em;
        }
      }
    }
    >.container {
      >.row {
        margin-left: -10px;
        margin-right: -10px;
      }
    }
  }
}