#sponsor-program {
    .page-subtitle {
        margin-bottom: 15px;
        @include media-breakpoint-up(lg){
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 55px;
        }
    }
    >div {
        div {
            min-height: 230px;
            background-size: cover!important;
            background-position: center!important;
            background-repeat: no-repeat!important;
            @include media-breakpoint-up(lg){
                min-height: 300px;
                width: 100%;
            }
            @include media-breakpoint-up(xl){
                min-height: 400px;
            }
        }
        @include media-breakpoint-up(lg){
            display: flex;
        }
        .text-part {
            @include media-breakpoint-up(lg){
                padding-left: 35px;
            }
            p {
                margin-top: 10px;
                color: #000;
                @include media-breakpoint-up(sm){
                    font-size: 16px;
                }
                @include media-breakpoint-up(lg){
                    font-size: 15px;
                    line-height: 18px;
                }
                &:first-child {
                    margin-top: 15px;
                    margin-bottom: 18px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: 0.03em;
                    @include media-breakpoint-up(lg){
                        margin-top: 0;
                    }
                }
            }
            .btn {
                display: none;
                max-height: 48px;
                align-items: center;
                margin: 30px auto auto;
                @include media-breakpoint-up(lg){
                    max-height: 58px;
                    margin: 40px 0px  auto;
                    padding-inline: 90px;
                }
            }
        }
    }
}
//Display sponsorship button only if the customer is logged
.customer-logged #sponsor-program>div .text-part .btn {
    display: flex;
}