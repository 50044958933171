#lgcookieslaw_banner {
    padding: 0!important;
    background: $paper;
    @include media-breakpoint-up(xl) {
        padding: 20px 0!important;
    }
    > .container {
        padding-top: 0!important;
        padding-bottom: 0!important;
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-end;
        }
        @include media-breakpoint-up(xxl) {
            max-width: 1320px;
        }
    }
    .lgcookieslaw-banner-message {
        margin-bottom: 25px;
        @include media-breakpoint-up(md) {
            width: 100%;
            margin-right: 0;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 20px;
            flex-grow: 1;
            margin-bottom: 20px;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 70px;
        }
        .lgcookieslaw-link-container {
            margin-top: 15px;
        }
    }
    .lgcookieslaw-button-container {
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
        }
        @include media-breakpoint-up(md) {
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            width: fit-content;
        }
        .lgcookieslaw-button {
            text-transform: none;
            padding: 12px;
            min-height: fit-content;
            @include media-breakpoint-up(md) {
                width: fit-content;
                padding: 12px 50px;
            }
            @include media-breakpoint-up(lg) {
                padding: 12px 30px;
                width: max-content;
            }
            &.lgcookieslaw-reject-button {
                border: 1px solid $tertiary!important;
                margin-bottom: 10px;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}