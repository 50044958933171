[id^="module-allinone_rewards-"]{
    #wrapper{
        .row{
            #content-wrapper{
                #main{
                    #content{
                        box-shadow: none;
                        margin-top: 13px;
                        .table{
                            thead{
                                th{
                                    @include media-breakpoint-down(lg){
                                        font-size: 11px;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        .rewards{    
                            margin: 0 auto;
                            max-width: 1078px;
                            background-color: #fff;                        
                            .idTabs{
                                margin-bottom: 0;
                                *{
                                    color: #000;
                                }
                                li{
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    a{
                                        padding: 0;
                                        font-size: 15px;
                                        font-weight: 600;
                                        border-top: none;
                                        border-radius: 0;
                                        line-height: 39px;
                                        border: 1px solid #ccc;
                                        text-transform: inherit;
                                        background-color: #f6f6f6;
                                        &.selected{
                                            background-color: #fff;
                                        }
                                        @include media-breakpoint-up(lg){
                                            font-size: 18px;
                                        }
                                    }
                                    @include media-breakpoint-down(lg){
                                        a{
                                            border-top: none;
                                        }
                                        &:first-child{
                                            a{
                                                border-top: 1px solid #ccc;
                                            }
                                        }
                                        &:last-child{
                                            a{
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .sheets{
                                padding-bottom: 20px;
                                @include media-breakpoint-up(lg){
                                    padding: 15px 21px;
                                }
                                .sponsorshipBlock{
                                    *{
                                        font-size: 13px;
                                        font-weight: 300;
                                        line-height: 15px;
                                        text-align: justify;
                                        @include media-breakpoint-up(lg){
                                            font-size: 15px;
                                            line-height: 18px;
                                        }
                                    }
                                    strong{
                                        font-weight: 600;
                                    }
                                    p{
                                        margin: 0;
                                    }
                                    table{
                                        *{
                                            color: #000;
                                        }
                                    }
                                    #sponsorship_text{
                                        *{
                                            color: #000;
                                        }
                                    }
                                    #sponsorship_form{
                                        .sponsorship-text{
                                            color: #000;
                                            *{
                                                color: #000;
                                            }
                                        }
                                        .sponsorship-fields{
                                            margin-top: 22px;
                                            #list_contacts_form{
                                                width: 100%;
                                                max-width: 100%;
                                                label{
                                                    color: #000;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    @include media-breakpoint-up(lg){
                                                        font-size: 15px;
                                                        line-height: 18px;
                                                    }
                                                }
                                                .form-control{
                                                    border: none;
                                                    height: 42px;
                                                    font-size: 13px;
                                                    box-shadow: none;
                                                    padding: 6px 10px;
                                                    background-color: $gray-bgfield;
                                                    @include media-breakpoint-up(lg){
                                                        height: 45px;
                                                    }
                                                }
                                                textarea{
                                                    &.form-control{
                                                        height: 88px;
                                                    }                                                    
                                                }
                                                .table{
                                                    border: none;
                                                    margin-top: 21px;
                                                    margin-bottom: 29px;
                                                    thead{
                                                        th{                                                          
                                                            font-size: 13px;
                                                            font-weight: 400;
                                                            text-transform: inherit;
                                                            background-color: $paper;
                                                            &:first-child{
                                                                padding: 4px 2px;
                                                            }
                                                            @include media-breakpoint-up(lg){
                                                                font-size: 15px;
                                                                line-height: 18px;
                                                                &:first-child{
                                                                    width: 76px;
                                                                }
                                                            }
                                                            @include media-breakpoint-down(lg){
                                                                border: none;
                                                            }
                                                        }
                                                    }
                                                    tbody{
                                                        tr{
                                                            td{                                                                
                                                                padding: 4px 9px;
                                                                @include media-breakpoint-up(lg){
                                                                    padding: 8px 18px;
                                                                }
                                                                @include media-breakpoint-down(lg){
                                                                    border: none;
                                                                }
                                                                &:first-child{
                                                                    padding: 4px 0;
                                                                    font-size: 13px;
                                                                    font-weight: 400;
                                                                    text-align: center;
                                                                    vertical-align: middle;
                                                                    @include media-breakpoint-up(lg){
                                                                        font-size: 20px;
                                                                    }
                                                                }
                                                                @include media-breakpoint-up(lg){
                                                                    font-size: 15px;
                                                                    line-height: 18px;
                                                                }
                                                                @include media-breakpoint-down(lg){
                                                                    &:last-child{
                                                                        padding-right: 0;
                                                                    }
                                                                }                                                                
                                                            }
                                                            &:first-child{
                                                                td{
                                                                    padding-top: 9px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .sponsorship-notice{
                                                    color: #000;
                                                    font-size: 11px;
                                                    line-height: 13px;
                                                    text-align: justify;
                                                    margin-bottom: 21px;   
                                                    @include media-breakpoint-up(lg){
                                                        font-size: 13px;
                                                        line-height: 15px;
                                                    }                                                 
                                                }
                                                .custom-checkbox{
                                                    padding-left: 34px;
                                                    margin-bottom: 23px;
                                                    label,
                                                    a{
                                                        color: #000;
                                                        font-size: 11px;
                                                        font-weight: 400;
                                                        line-height: 13px;
                                                        display: inline-block;
                                                        vertical-align: middle;
                                                        @include media-breakpoint-up(lg){
                                                            font-size: 13px;
                                                            line-height: 15px;
                                                        }
                                                    }
                                                }
                                                .link-mail,
                                                .link-mail a{
                                                    color: #000;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    line-height: 15px;
                                                    margin-bottom: 33px;
                                                    display: inline;
                                                }
                                                .form-footer{
                                                    .btn{
                                                        padding: 0;
                                                        width: 302px;
                                                        font-size: 17px;
                                                        font-weight: 600;
                                                        line-height: 50px;
                                                        text-align: center;
                                                        font-family: 'Raleway';
                                                        background-color: $tertiary-light;
                                                        &:hover{
                                                            background-color: $tertiary !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }                                        
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}



/* POPIN PARRAINAGE */
.sponsorship_popup_container{
    .fancybox-skin{
        border-radius: 0;
        padding: 0 !important;
        .fancybox-outer{
            .fancybox-inner{
                .sponsorshipPopup{
                    background-color: #fff;
                    .back{
                        padding: 5px 15px;
                        position: absolute;
                        background-color: $paper;
                        a{
                            font-size: 16px;
                            color: $secondary;
                            @include media-breakpoint-down(lg){
                                font-size: 13px;
                            }
                        }
                    }
                    .popup-header{
                        height: 63px;
                        display: flex;
                        margin-bottom: 7px;
                        align-items: center;
                        justify-content: center;
                        background: url('../img/design/popup-header-left.svg') no-repeat 5px center, url('../img/design/popup-header-right.svg') no-repeat 95% center; 
                        background-color: $paper;
                        @include media-breakpoint-up(lg){
                            height: 74px;
                            margin-bottom: 16px;
                        }
                        span{                                    
                            color: #000;
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: center;
                            display: inline-block;
                            @include media-breakpoint-down(lg){
                                width: 122px;
                                font-size: 18px;
                            }
                        }
                    }
                    .popup-content{
                        padding: 0 15px;
                        *{
                            color: #000;
                            font-size: 13px;
                            font-weight: 300;
                            line-height: 15px;
                            margin-top: 7px;
                            margin-bottom: 24px;
                            @include media-breakpoint-up(lg){
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }
                    }
                    #sponsorship_text{
                        @include media-breakpoint-down(lg){
                            padding-bottom: 10px;
                        }                            
                        > div{
                            #invite{
                                padding: 0;
                                width: 273px;
                                color: #fff;
                                font-size: 15px;
                                font-weight: 600;
                                line-height: 42px;
                                font-family: 'Raleway';
                                text-align: center;
                                align-items: center;
                                justify-content: center;
                                background-color: $tertiary-light;
                                @include media-breakpoint-up(lg){
                                    font-size: 18px;
                                }
                                &:hover{
                                    background-color: $tertiary !important;
                                }
                            }
                            #noinvite{
                                padding: 0;
                                color: #000;
                                font-size: 15px;
                                box-shadow: none;
                                font-weight: 400;
                                line-height: 42px;
                                text-decoration: underline;
                                background-color: transparent;
                                &:hover{
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                    #sponsorship_form{
                        padding: 25px;
                        *{
                            color: #000;
                            font-size: 13px;
                            font-weight: 300;
                            line-height: 15px;
                            margin-top: 7px;
                            margin-bottom: 24px;
                            @include media-breakpoint-up(lg){
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }
                        ul{
                            margin-top: 24px;
                            li{
                                margin-top: 0;
                                padding-top: 0;
                                margin-bottom: 10px;
                                img{
                                    margin-bottom: 0;
                                }
                                strong{
                                    font-weight: 600;
                                }
                            }
                        }
                        .table{
                            thead{
                                tr{
                                    th{
                                        font-size: 14px;
                                        font-weight: 500;
                                        @include media-breakpoint-down(md){
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    td{
                                        .form-control{
                                            border: none;
                                            margin: 0 auto;
                                            box-shadow: none;
                                        }
                                        &:first-child{
                                            font-weight: 400;
                                            vertical-align: middle;
                                        }
                                    }
                                }
                            }
                        }
                        .custom-control{
                            a,
                            label{
                                display: inline;
                            }
                        }
                        .form-footer{
                            *{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .fancybox-close{
            background: url('../img/design/popup-close.svg') no-repeat center;
        }
    }
}