.page-category,
.page-manufacturer,
.page-search {
    .breadcrumb {
        padding-inline: 0;
    }
    /* PAGE HEADER */
    #category-header {
        .category-description-wrapper {
            margin-top: 20px;
            @include media-breakpoint-up(xxl) {
                margin-top: 30px;
            }
            p,
            ul,
            li {
                font-size: 13px;
                line-height: 15px;
                text-align: justify;
                letter-spacing: .02em;
                margin-top: 20px;
                margin-bottom: 0;
                @include media-breakpoint-up(sm){
                    font-size: 20px;
                    line-height: 22px;
                }
                @include media-breakpoint-up(lg){
                    line-height: 24px;
                }
                @include media-breakpoint-up(xxl){
                    margin-top: 30px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                }
            }
            .more-less-text {
                display: block;
                font-weight: 700;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    /* FILTERS */
    .filter-and-order-by_wrapper,
    .mobile-filters-wrapper {
        .facet__block,
        label[for=select-sort-order] {
            padding: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.03em;
        }
        .facet__title,
        #select-sort-order {
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.03em;
            border: none;
            box-shadow: none;
        }
        #search_filters {
            .dw-fullwidth-facets {
                .facet__block {
                    &:first-child {
                        margin-right: 10px;
                    }
                    position: relative;
                    @include media-breakpoint-down(md){
                        margin-bottom: 10px;
                    }
                    .facet__header {
                        @include media-breakpoint-up(lg){
                            padding-inline: 15px;
                        }
                        .facet__title {
                            margin-right: 10px;
                        }
                        .icon-collapse {
                            &[aria-expanded=true]{
                                svg {
                                    margin-right: 0.5rem;
                                    margin-left: 0!important;
                                }
                            }
                        }
                    }
                    .dw-fullwidth-facet {
                        margin-top: 15px;
                        @include media-breakpoint-up(lg){
                            position: absolute;
                            top: 30px;
                            background-color: white;
                            padding: 15px;
                            z-index: 1;
                            width: 275px;
                            margin-top: 0;
                        }
                        li {
                            p {
                                margin-top: 0;
                                margin-bottom: 15px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20px;
                                letter-spacing: 0.03em;
                                text-align: left;
                                @include media-breakpoint-up(xxl){
                                    text-align: center;
                                }
                            }
                            .ui-slider-horizontal {
                                border: none;
                                .ui-slider-range {
                                    background: $yellow;
                                }
                            }
                        }
                        .custom-checkbox {
                            label {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                .color {
                                    box-shadow: none;
                                    margin-right: 10px;
                                }
                                .color__label {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    letter-spacing: 0.03em;
                                }
                                // &::before,
                                // &::after {
                                //     top: 2px;
                                // }
                            }
                        }
                        .custom-radio {
                            label {
                                .color__label {
                                    font-weight: 400;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .products-selection {
            @include media-breakpoint-down(md){
                position: fixed!important;
                z-index: 3000;
                width: 100%;
                .filter-buttons {
                    background-color: #055E50;
                    border-top-left-radius: 20.5px;
                    border-top-right-radius: 20.5px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    min-height: 70px;
                    .filter-btn,
                    .filter-buttons--sort_by {
                        width: 50%;
                        max-width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .filter-btn {
                        border-right: 1px solid white;
                        button {
                            &:hover {
                                background-color: transparent!important;
                            }
                        }
                    }
                    button,
                    .filter-buttons--sort_by #select-sort-order {
                        background: none;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 23px;
                        padding-inline: 25px;
                        color: #FFFFFF;
                        justify-content: space-between;
                    }
                    .filter-buttons--sort_by {
                        #select-sort-order {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 23px;
                            padding-right: 58px;
                            background: transparent;
                            background-image: url("data:image/svg+xml,<svg width='29' height='17' viewBox='0 0 29 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M28 1L14.5 15L1 1' stroke='white' stroke-width='2'/></svg>");
                            background-repeat: no-repeat;
                            background-position-x: calc(100% - 25px);
                            background-position-y: 50%;
                        }
                    }
                }
            }
            bottom: 0;
            right: 0;
            #select-sort-order {
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                background-image: url("data:image/svg+xml,<svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7 7L13 1' stroke='#4F4F4F' stroke-width='1.5'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 100%;
                background-position-y: 50%;
                padding: 0;
                height: fit-content;
                padding: 0 15px 0 10px;
                option {
                    min-height: 20px;
                }
            }
        }
    }
    .filter-and-order-by_wrapper {
        @include media-breakpoint-down(lg){
            min-height: 45px;
        }
    }

    /* PRODUCTS */
    .products {
        margin-right: -11px;
        margin-left: -11px;
        .product-miniature {
            padding-inline: 11px;
            .card-product {
                border: none;
                .card-img-top {
                    img {
                        min-width: 100%;
                    }
                }
                .card-body {
                    padding: 0;
                    padding-top: 10px;
                    .product-description {
                        height: 50px;
                        max-height: 50px;
                        align-items: flex-start;
                        .product-title {
                            font-family: 'Prompt';
                            font-size: 13px;
                            line-height: 13px;
                            color: #4F4F4F;
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .pagination {
        .page-item {
            .page-link {
                font-family: 'Prompt';
                font-size: 19px;
                line-height: 23px;
                letter-spacing: 0.03em;
                color: #000000;
                border: none;
                background-color: transparent;
                padding-top: 0;
                padding-bottom: 0;
                padding-inline: 5px;
                margin-inline: 5px;
                border-bottom: 1px solid transparent;
            }
            &.active {
                .page-link {
                    border-bottom: 1px solid black;
                }
            }
        }
    }

    footer#footer {
        @include media-breakpoint-down(md){
            margin-bottom: 70px;
        }
    }

    /* Handle backdrop shadows for all modals */
    #offcanvas_search_filter {
        z-index: 4000;
    }
    #mobile_top_menu_wrapper {
        z-index: 3800;
    }
    #header .header-top {
        background-color: $paper;
        position: relative;
        z-index: 3850;
    }
    .modal-backdrop.show {
        opacity: 0.5!important;
        z-index: 3500;
    }
}

.price {
    font-size: 13px;
    line-height: 20px;
    text-align: left;
}
.price-without-discount {
    color: $secondary !important;
    text-decoration: line-through;
    margin-right: 5px;
}
.product-flag {
    position: absolute;
    margin-top: 0;
    right: 0;
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 10px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: $paper;
    text-transform: none;
    background-color: $yellow;
    padding-inline: 8px;
}