#product {
    #wrapper {
        padding-inline: 0;
    }
    .breadcrumb {
        @include media-breakpoint-up(lg) {
            padding-inline: 60px;
        }
        @include media-breakpoint-up(xxl) {
            padding-inline: 0;
            max-width: 1320px;
            margin: auto;
        }
    }
    h1, .h1 {
        margin-bottom: 5px;
        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }
    .images-container {
        margin-top: 20px;
        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
        .product-images-swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-wrapper {
                flex-wrap: nowrap;
                .swiper-slide {
                    min-width: 100%;
                    position: relative;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin: auto;
                    }
                    .product-tag {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-color: $yellow;
                        color: $paper;
                        padding: 10px 20px;
                        font-size: 13px;
                        line-height: 30px;
                        letter-spacing: 0.03em;
                    }
                }
            }
            .swiper-navigation {
                display: flex;
                justify-content: space-between;
                padding-inline: 15px;
                position: absolute;
                top: calc(50% - 14px);
                width: 100%;
                @include media-breakpoint-up(lg){
                    top: 45%;
                }
                .swiper-button {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .swiper-pagination {
                margin-left: -0.5rem;
                margin-right: -0.5rem;
                .swiper-pagination-bullet {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .product-prices {
        .product-price {
            .current-price {
                .price {
                    font-size: 18px;
                    line-height: 20px;
                    color: #000000;
                    @include media-breakpoint-up(lg){
                        font-size: 20px;
                    }
                }
                .including-taxes {
                    font-weight: 400;
                    color: #000;
                    font-size: 14px;
                    @include media-breakpoint-up(lg){
                        font-size: 16px;
                    }
                }
            }
        }
        .deposit {
            p {
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                margin-top: 0;
            }
        }
    }
    .product-information {
        .product-short-description {
            order: 1;
            >p {
                letter-spacing: 0.03em;
                margin-top: 0;
                margin-bottom: 25px;
                font-family: 'Raleway';
                @include media-breakpoint-up(lg){
                    font-size: 15px;
                    line-height: 18px;
                    margin-top: 20px;
                }
            }
        }
        .product-tabs {
            border: none;
            padding: 0;
            order: 5;
            .nav-tabs {
                border-bottom: none;
                margin-bottom: 30px;
                .nav-item {
                    span {
                        font-size: 20px;
                        letter-spacing: 0.03em;
                        color: #000000;
                        font-family: 'Prompt';
                    }
                    .navbar-toggler {
                        height: 17px;
                        width: auto;
                        margin-left: 10px;
                        &[aria-expanded=true]{
                            display: none;
                        }
                        &.icon-hide {
                            display: none;
                            &[aria-expanded=true]{
                                display: block;
                            }
                        }
                    }
                }
            }
            .tab-content {
                padding-top: 0px;
                .tab-pane {
                    padding-top: 15px;
                    padding-bottom: 20px;
                    margin-top: -30px;
                    &.show {
                        display: block;
                    }
                    p,
                    ul li {
                        margin-top: 0;
                        font-weight: 400;
                        font-size: 14.5px;
                        line-height: 17px;
                        letter-spacing: 0.03em;
                    }
                    p {
                        margin-bottom: 15px;
                    }
                    ul {
                        padding-top: 10px;
                        padding-left: 30px;
                        li {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .product-additional-info {
            order: 2;
            .product-add-to-cart{
                #product-availability{
                    margin-bottom: 20px; 
                }
            }
            .product-certifications {
                margin-bottom: 30px;
                p {
                    font-weight: 700;
                    font-size: 14.5px;
                    line-height: 17px;
                    letter-spacing: 0.03em;
                    padding-right: 10px;
                    margin-top: 0;
                }
                img {
                    max-height: 27px;
                    width: auto;
                }
            }
            .notify-me {
                .js-mailalert {
                    p {
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: 0.03em;
                        color: #000000;
                        font-family: 'Prompt';
                    }
                    input {
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: 0.03em;
                        border: none;
                        box-shadow: none;
                        background-color: #F0F0F0;
                    }
                    button {
                        padding: 10px 60px;
                        @include media-breakpoint-up(lg){
                            height: 48px;
                            align-items: center;
                        }
                    }
                    .js-mailalert-alerts {
                        display: none;
                    }
                }
            }
        }
    }
    .product-actions,
    .product-additional-info {
        .product-add-to-cart {
            margin-top: 30px;
            order: 4;
            .product-quantity {
                margin-bottom: 35px;
                label {
                    margin-top: 0;
                    margin-bottom: 18px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: #000000;
                }
                .input-group {
                    #quantity_wanted {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
                .input-group.bootstrap-touchspin {
                    width: 100%;
                    justify-content: space-between;
                    min-height: 48px;
                    align-items: center;
                    background-color: $paper;
                    * {
                        background-color: transparent;
                    }
                    .input-group-btn {
                        .btn {
                            padding: 10px;
                            font-size: 25px;
                            line-height: 20px;
                            &:hover {
                                background-color: transparent!important;
                            }
                        }
                    }
                }
            }
            #product-availability {
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: black;
                svg {
                    margin-right: 5px;
                }
            }
            .add {
                margin-top: 15px;
                margin-bottom: 25px;
                .add-to-cart.btn {
                    font-size: 18px;
                    line-height: 20px;
                    text-transform: initial;
                }
            }
        }
        .product-variants {
            margin-top: 20px;
            .variant-wrapper {
                margin-bottom: 20px;
                .variant-name {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: #000000;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                .variant-group {
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 0;
                    li {
                        height: 100%;
                        min-height: 48px;
                        margin-bottom: 15px;
                        label,
                        input {
                            display: block;
                            width: 100%;
                            height: 100%;
                            min-height: 48px;
                            &[type="radio"] {
                                opacity: 0.01;
                                z-index: 100;
                                &:checked+label {
                                background: $pink;
                                }
                            }
                        }
                        label {
                            font-size: 17px;
                            line-height: 20px;
                            color: #000000;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $paper;
                            padding-inline: 15px;
                            &::before,
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-actions {
        order: 3;
        form{
            .product-add-to-cart{
                #product-availability{
                    display: none;
                }
            }
        }
    }
    .desktop-product-tabs {
        margin-bottom: 90px;
        margin-top: 50px;
        .custom-nav-tabs {
            @include media-breakpoint-up(lg){
                margin-bottom: 30px;
            }
            li {
                font-family: 'Prompt';
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.03em;
                color: #000000;
                border-bottom: 3px solid transparent;
                margin-inline: 50px;
                &:hover {
                    cursor: pointer;
                }
                &.active {
                    border-color: transparent transparent $secondary;
                }
            }
        }
        .custom-tab-content {
            div {
                display: none;
                &.show {
                    display: block;
                }
                p,
                ul li {
                    font-size: 18px;
                    line-height: 21px;
                    letter-spacing: 0.03em;
                }
                ul {
                    padding-top: 10px;
                    padding-left: 35px;
                }
            }
        }
    }
}