#our-commitments {
    margin-bottom: 20px;
    .page-subtitle {
        margin-bottom: 25px;
        @include media-breakpoint-up(sm){
            font-size: 14px;
            line-height: 16px;
        }
        @include media-breakpoint-up(lg){
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 60px;
        }
    }
    .commitments {
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            margin-bottom: 70px;
        }
        .commitment {
            @include media-breakpoint-up(lg){
                min-height: 100%;
            }
            .image {
                min-height: 205px;
                height: 100%;
                background-position: center!important;
                background-size: cover!important;
                background-repeat: no-repeat!important;
            }
            .text {
                .title {
                    margin-top: 15px;
                    font-family: 'Prompt';
                    font-size: 15px;
                    line-height: 18px;
                    text-align: left;
                    letter-spacing: 0.03em;
                    color: #000;
                    @include media-breakpoint-up(lg){
                        font-size: 20px;
                        line-height: 26px;
                        margin-bottom: 35px;
                        margin-top: 0;
                    }
                    span {
                        color: #000;
                    }
                }
                p,
                ul li,
                ul li * {
                    letter-spacing: 0.03em;
                    color: #000;
                    margin-top: 5px;
                    font-size: 13px;
                    line-height: 15px;
                    @include media-breakpoint-up(sm){
                        font-size: 14px;
                        line-height: 16px;
                    }
                    @include media-breakpoint-up(lg){
                        font-size: 18px;
                        line-height: 21px;
                        margin-top: 10px;
                    }
                    @include media-breakpoint-up(xxl){
                        font-weight: 500;
                    }
                }
                ul {
                    padding-left: 16px;
                    margin-top: 15px;
                    li {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg){
        .commitments:nth-child(3),
        .commitments:nth-child(5),
        .commitments:nth-child(7) {
            flex-direction: row-reverse;
        }
    }
    .btn {
        width: 100%;
        max-height: 50px;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(lg){
            width: fit-content;
            padding-inline: 90px;
            margin: auto;
        }
    }
}