.page-order-confirmation{
    #wrapper{
        nav{
            .breadcrumb{
                .breadcrumb-item{
                    a{
                        span{
                            &.visible--mobile{
                                @include media-breakpoint-down(lg){
                                    display: flex;
                                    align-items: center;
                                }
                                svg{
                                    width: 9px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .row{
            #content-wrapper{
                #main{                    
                    .header-confirmation{
                        margin-bottom: 51px;
                        *{
                            color: #000;
                        }
                        .page-header{                            
                            svg{
                                margin-bottom: 19px;
                            }
                            .page-title{
                                font-family: 'Prompt';
                                font-size: 23px;
                                font-weight: 400;
                                line-height: 28px;
                                margin-bottom: 12px;
                            }
                        }
                        .page-desc{
                            font-family: 'Prompt';
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 25px;
                            margin-bottom: 32px;
                        }
                        .page-links{
                            .btn-back{
                                padding: 0;
                                width: 100%;
                                max-width: 368px;
                                font-family: 'Raleway';
                                font-size: 17px;
                                font-weight: 600;
                                color: #fff;
                                line-height: 50px;
                                margin: 0 auto 15px;
                                justify-content: center;
                            }
                            .link-history{
                                font-size: 17px;
                                font-weight: 600;
                                text-decoration: underline;
                            }
                        }
                    }
                    .page-content{
                        padding: 30px;
                        margin: 0 -30px;
                        background-color: $paper;
                        *{
                            color: #000;
                        }
                        @include media-breakpoint-up(md){
                            margin: 0 auto;
                            max-width: 642px;
                        }
                        .order-summary{
                            .os-title{
                                font-family: 'Prompt';
                                font-size: 19px;
                                line-height: 22px;
                                text-align: center;
                                margin-bottom: 18px;
                            }
                            .os-emplacement{
                                margin-bottom: 30px;
                                .ose-infos{
                                    display: flex;
                                    padding: 25px 0;
                                    align-items: flex-start;
                                    justify-content: center;
                                    border-top: 1px solid #000;
                                    border-bottom: 1px solid #000;
                                    .ose-data{
                                        padding-left: 14px;
                                        .ose-creneau{
                                            font-size: 15px;
                                            font-weight: 600;
                                            line-height: 18px;
                                            margin-bottom: 7px;
                                        }
                                        .ose-adresse{
                                            font-size: 13px;
                                            font-weight: 500;
                                            line-height: 15px;
                                            .ose-name{
                                                font-weight: 500;
                                                font-size: 15px;
                                                line-height: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                            .os-products{
                                .osp-item{
                                    display: flex;
                                    margin-bottom: 30px;
                                    align-items: flex-start;
                                    .osp-thumb{
                                        flex: 0 1 23%;
                                        @include media-breakpoint-up(lg){
                                            flex: 0 1 12%;
                                        }
                                    }
                                    .osp-details{
                                        flex: 0 1 77%;
                                        padding-left: 23px;
                                        @include media-breakpoint-up(lg){
                                            flex: 0 1 37%;
                                            padding-left: 14px;
                                        }
                                        .osp-name{
                                            font-weight: 600;
                                            font-size: 15px;
                                            line-height: 18px;
                                            margin-bottom: 10px;
                                        }
                                        .osp-attributs{
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 18px;
                                            margin-bottom: 12px;
                                        }
                                        .osp-price{
                                            @include media-breakpoint-down(lg){
                                                display: flex;
                                                justify-content: space-between;
                                            }
                                        }
                                    }
                                    .osp-price{
                                        &.visible--desktop{
                                            flex: 0 1 32%;
                                            font-size: 15px;
                                            font-weight: 500;                                            
                                            margin-left: auto;             
                                            @include media-breakpoint-up(lg){
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                            }
                                        }
                                    }
                                }
                                .osp-total{
                                    padding-top: 15px;
                                    border-top: 1px solid #000;
                                    .osp-bloc{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        *{
                                            font-size: 16px;
                                        }
                                        .osp-label{
                                            font-weight: 700;
                                            text-transform: uppercase;
                                        }
                                        .osp-value{
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}