.page-order-slip {
    #wrapper {
        #content-wrapper {
            .container {
                .page-content {
                    table{
                        text-align: center;
                        td{
                            i{
                                color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}