.page-history{
    #wrapper{
        .row{
            margin: 0 auto;
            #content-wrapper{
                padding: 0;

                .container{
                    padding: 0;
                    @include media-breakpoint-up(sm){
                        padding: 0 15px;
                    }
                    .page-content{
                        margin-top: 25px;
                        max-width: 819px;
                        background-color: #fff;
                        @include media-breakpoint-up(lg){
                            padding: 37px 62px;
                        }
                        .order-list{
                            .ol-item{
                                display: inline-block;
                                width: 100%;
                                padding: 16px 18px;
                                margin-bottom: 24px;
                                background-color: $paper;
                                .order-summary{
                                    // display: flex;
                                    // align-items: stretch;
                                    .ol-infos {
                                        width: 71%; 
                                        padding-left: 15px;
                                        @include media-breakpoint-up(sm){
                                            width: 74%;
                                        }
                                        @include media-breakpoint-up(lg){                                            
                                            padding-left: 30px;                                            
                                        }
                                        
                                        *{
                                            color: #000;
                                        }                                        
                                        .ol-date{
                                            font-family: 'Prompt';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 12px;
                                            margin-bottom: 5px;
                                            letter-spacing: 0.03em;
                                            @include media-breakpoint-up(sm){
                                                font-size: 20px;
                                                margin-bottom: 11px;
                                            }
                                        }
                                        .ol-ref{
                                            font-family: 'Raleway';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            letter-spacing: 0.03em;
                                            margin-bottom: 10px;
                                            @include media-breakpoint-up(sm){
                                                font-size: 15px;
                                                margin-bottom: 26px;
                                            }
                                        }
                                        .ol-details {
                                            display: flex;
                                            align-items: center;   
                                            flex-wrap: wrap;                                         
                                            .ol-bloc{
                                                font-family: 'Raleway';
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 18px;
                                                letter-spacing: 0.03em;
                                                padding: 0 5px;
                                                border-right: 1px solid #000;
                                                @include media-breakpoint-up(sm){
                                                    font-size: 15px;
                                                    padding: 0 25px;
                                                }
                                                &:first-child{
                                                    padding-left: 0;                                                    
                                                }
                                                &.ol-qty{                                                       
                                                    @include media-breakpoint-down(sm){
                                                        margin-right: 5px; 
                                                    }                                                
                                                }
                                                &:last-child{                                                    
                                                    border-right: none;
                                                    @include media-breakpoint-down(sm){
                                                        padding-left: 0;
                                                    } 
                                                    @include media-breakpoint-up(sm){
                                                        max-width: 220px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .ol-toggle-details {
                                        margin-left: auto;
                                        // display: flex;
                                        // align-items: center;
                                        padding: 0 0 0 10px;
                                        border-left: 1px solid #000;
                                        @include media-breakpoint-up(sm){
                                            padding: 0 0 0 15px;
                                        }
                                        svg{
                                            cursor: pointer;
                                            width: 7px;
                                            transition: transform ease-in-out 300ms;
                                            @include media-breakpoint-up(sm){
                                                width: 24px;
                                            }
                                            &.open{
                                                transform: rotate(-180deg);
                                            }
                                        }
                                    }
                                }   
                                .od-bloc{
                                    .order-details{
                                        display: none;
                                        margin-top: 16px;
                                        padding-top: 16px;
                                        max-width: 635px;
                                        border-top: 1px solid #bebebe;
                                        @include media-breakpoint-down(sm){
                                            border-top: none;
                                            position: relative;
                                            &::before{
                                                content: '';
                                                display: block;
                                                height: 1px;
                                                background-color: #bebebe;
                                                position: absolute;
                                                top: 0;
                                                right: 16px;
                                                left: 0;
                                            }
                                        }
                                        .od-products{
                                            width: 100%;
                                            td{
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 16px;
                                                padding-bottom: 20px;
                                                @include media-breakpoint-up(sm){
                                                    font-size: 15px;
                                                }
                                                &.odp-name {
                                                    width: 60%;
                                                    padding-right: 20px;                                                    
                                                    @include media-breakpoint-up(sm){
                                                        width: 300px;
                                                        padding-right: 100px;
                                                    }
                                                }
                                                &.odp-price{
                                                    text-align: right;
                                                    @include media-breakpoint-down(sm){
                                                        padding-right: 16px;
                                                    }
                                                }
                                            } 
    
                                        }  
                                        .od-payment{
                                            font-size: 12px;
                                            padding: 12px 0;
                                            margin-bottom: 19px;
                                            border-top: 1px solid #bebebe;
                                            border-bottom: 1px solid #bebebe;
                                            @include media-breakpoint-up(sm){
                                                font-size: 15px;
                                            }
                                            span{
                                                font-weight: 500;
                                            }
                                        }    
                                        .od-invoice{
                                            span{
                                                cursor: default;
                                            }
                                            .odi-download{
                                                font-family: 'Raleway';
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 17px;
                                                color: #fff;
                                                display: block;
                                                width: auto;
                                                height: 47px;
                                                line-height: 47px;
                                                text-align: center;
                                                background-color: #A3C1AE;
                                                margin-right: -18px;
                                                margin-left: -18px;
                                                margin-bottom: -16px;
                                                transition: background-color ease-in-out  300ms;
                                                
                                                @include media-breakpoint-up(sm){
                                                    width: 417px;
                                                    margin: 0 auto;
                                                }
                                            }
                                            a{
                                                &.odi-download{
                                                    &:hover{
                                                        background-color: $tertiary;
                                                    }
                                                }
                                            }
                                        }                                                                 
                                    }  
                                }                                                          
                            }
                        }
                    }
                }
            }
        }
    }
}