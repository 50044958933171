#module-tmdfaq-Allcategory {
    #content-wrapper {
        padding-inline: 0px;
        .page-wrapper {
            border: none;
            #content {
                padding: 0px;
                .no-question {
                    min-height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #000000;
                    background-color: $paper;
                    font-weight: 500;
                    margin-bottom: 50px;
                    padding-inline: 15px;
                    @include media-breakpoint-between(sm, lg) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    h1 {
        margin-bottom: 35px;
        @include media-breakpoint-up(xxl){
            margin-bottom: 55px;
        }
    }
    .accordion {
        .accordion-title {
            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            color: black;
            margin-top: 0;
            margin-bottom: 12px;
            @include media-breakpoint-up(xxl){
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
            }
        }
        .card {
            border: none;
            background: none;
            .card-header {
                padding: 0;
                background: none;
                width: 100%;
                button {
                    padding: 0;
                    background: none!important;
                    width: 100%;
                    color: #000;
                    min-height: 45px;
                    font-size: 13px;
                    line-height: 15px;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 0.5px solid #000;
                    text-align: left;
                    &:active {
                        box-shadow: none;
                    }
                    &:focus-visible {
                        outline: none;
                    }
                    &[aria-expanded=true] {
                        .faq-toggle-btn {
                            transform: rotate(180deg);
                            padding-right: 15px;
                            padding-left: 0;
                        }
                    }
                    @include media-breakpoint-up(xxl){
                        min-height: 53px;
                        font-size: 18px;
                        line-height: 21px;
                    }
                    .faq-toggle-btn {
                        height: 10px;
                        max-height: 10px;
                        padding-left: 15px;
                    }
                }
            }
            .card-body {
                padding: 10px 0px;
                *{
                    font-size: 12px;
                    line-height: 15px;
                }
                @include media-breakpoint-up(xxl){
                    *{
                        font-size: 17px;
                        line-height: 20px;
                    }
                }
            }
            &:last-child {
                margin-bottom: 65px;
                @include media-breakpoint-up(xxl){
                    margin-bottom: 100px;
                }
                .card-header {
                    border-bottom: none;
                    button {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    #new-question {
        position: relative;
        background-color: #FCF8F8;
        padding: 35px 30px 65px 30px;
        @include media-breakpoint-up(xxl) {
            padding: 37px 300px 33px 300px;
        }
        .title {
            font-size: 19px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #000000;
            font-family: 'Prompt';
            margin-top: 0;
            position: relative;
            z-index: 5;
            @include media-breakpoint-up(xxl){
                font-size: 28px;
                line-height: 30px;
                margin-bottom: 25px;
            }
        }
        p {
            letter-spacing: 0.03em;
            color: #000000;
            margin-top: 10px;
            @include media-breakpoint-between(sm, xxl) {
                font-size: 16px;
            }
            @include media-breakpoint-up(xxl){
                font-size: 15px;
                line-height: 18px;
            }
        }
        .faq-links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 48px;
            @include media-breakpoint-up(lg){
                flex-direction: row;
            }
            .btn {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #FFF;
                background-color: $secondary;
                width: fit-content;
                padding-inline: 30px;
                @include media-breakpoint-up(xxl){
                    min-height: 58px;
                    align-items: center;
                }
                &:first-child {
                    background-color: rgba(235, 112, 63, 0.50);
                    margin-bottom: 10px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0;
                        margin-right: 18px;
                    }
                }
            }
        }
        >img {
            position: absolute;
            &.faq-deco-1 {
                top: 0px;
                left: 0px;
                @include media-breakpoint-up(xxl){
                    left: -48px;
                    min-width: 123px;
                    max-height: 130px;
                }
            }
            &.faq-deco-2 {
                top: -23px;
                right: 3px;
                @include media-breakpoint-up(xxl){    
                    min-width: 123px;
                    top: -48px;
                    right: 25px;
                }
            }
            &.faq-deco-3 {
                left: 3px;
                bottom: 0px;
                @include media-breakpoint-up(xxl){
                    left: 75px;
                }
            }
            &.faq-deco-4 {
                bottom: 10px;
                right: 3px;
                @include media-breakpoint-up(xxl){
                    min-width: 213px;
                    right: 58px;
                    bottom: 0px;
                }
            }
        }
    }
}