.page-cms {
    .l-wrapper {
        padding-inline: 0;
    }
    .page-wrapper {
        border: none;
        .page-header {
            background: none;
            border: none;
            padding-top: 0;
            padding-bottom: 0;
        }
        .page-content {
            padding: 0;
            .page-subtitle {
                margin-top: 10px;
                margin-bottom: 30px;
                @include media-breakpoint-up(sm){
                    font-size: 16px;
                }
                @include media-breakpoint-up(lg){
                    margin-bottom: 65px;
                }
            }
            #deposit-managment {
                .managments {
                    .managment {
                        @include media-breakpoint-up(lg){
                            display: flex;
                            align-items: center;
                            margin-bottom: 38px;
                        }
                        .img-wrapper {
                            position: relative;
                            margin-bottom: 35px;
                            @include media-breakpoint-up(lg){
                                margin-bottom: 0;
                            }
                            >img {
                                width: 100%;
                                @include media-breakpoint-up(lg){
                                    min-width: 295px;
                                    max-width: 295px;
                                }
                            }
                            .sticker {
                                position: absolute;
                                bottom: -28.5px;
                                width: 100%;
                                text-align: center;
                                @include media-breakpoint-up(lg){
                                    bottom: calc(50% - 29.5px);
                                    right: -28.5px;
                                    text-align: right;
                                    &.sticker-1 {
                                        span {
                                            right: 25px;
                                        }
                                    }
                                }
                                >span {
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    font-family: 'Prompt';
                                    font-size: 24px;
                                    color: #FFF;
                                    font-weight: 500;
                                    @include media-breakpoint-up(lg){
                                        justify-content: flex-end;
                                        right: 22.5px;
                                    }
                                }
                            }
                        }
                        .text-wrapper {
                            margin-bottom: 28px;
                            @include media-breakpoint-up(lg){
                                padding-left: 50px;
                                margin-bottom: 0;
                            }
                            p {
                                letter-spacing: 0.03em;
                                @include media-breakpoint-up(lg){
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
                .btns-wrapper {
                    margin-top: 15px;
                    @include media-breakpoint-up(lg){
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .btn {
                        max-height: 48px;
                        align-items: center;
                        margin: auto;
                        @include media-breakpoint-up(lg){
                            margin: initial;
                            min-height: 58px;
                        }
                        &:first-child {
                            margin-bottom: 15px;
                            @include media-breakpoint-up(lg){
                                margin-right: 18px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    #footer {
        padding-top: 0;
        margin-top: 75px;
        @include media-breakpoint-up(lg){
            margin-top: 120px;
        }
    }
}