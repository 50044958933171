.page-my-account {
    #wrapper {
        @include media-breakpoint-up(lg){
            background-color: $paper;
            .breadcrumb {
                background-color: $paper;
            }
            #content-wrapper {
                width: 100%;
            }
        }
    }
    h1 {
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .page-content--my-account {
        background-color: $paper;
        padding-top: 25px;
        padding-bottom: 35px;
        .account-links {
            a {
                margin-bottom: 20px;
                .link-item {
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
                    min-height: 130px;
                    align-items: center;
                    justify-content: center;
                    .item-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg,
                        i {
                            margin-right: 15px;
                            font-size: 37px;
                            color: $secondary;
                            margin-bottom: 0;
                        }
                        img {
                            max-height: 28px;
                            width: auto;
                            margin-right: 15px;
                        }
                        p {
                            margin-top: 0;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            letter-spacing: 0.03em;
                            color: $tertiary;
                            text-align: left;
                        }
                    }
                }
                &#logout-link {
                    .link-item {
                        p {
                            color: #D62836;
                        }
                    }
                }
            }
        }
    }
}