.page-discount {
    .page-wrapper {
        background: none;
        border: none;
        .page-header {
            background: none;
            border: none;
            padding: 0;
        }
        .page-content {
            padding: 0;
            #notifications {
                margin-top: 15px;
                @include media-breakpoint-up(lg){
                    margin-top: 30px;
                }
                ul {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    li {
                        text-align: center;
                        margin-top: 10px;
                        &:first-child {
                            margin-top: 0px;
                        }
                    }
                }
            }
            .cart-rules {
                margin-top: 15px;
                .cart-rule {
                    ul {
                        background-color: #FCF8F8;
                        padding: 15px;
                    }
                }
            }
            table {
                margin-top: 40px;
                tbody {
                    tr:nth-child(odd) {
                        background-color: #FCF8F8;
                    }
                }
            }
        }
    }
}