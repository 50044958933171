.page-order{
    #wrapper{
        nav{
            padding: 0;
            .breadcrumb{
                padding: 32px 0;
                margin: 0 auto;
                a{
                    span{
                        font-family: 'Prompt';
                        font-size: 16px;
                        color: #000;
                        display: flex;
                        align-items: center;
                        svg{
                            width: 9px;
                            height: 18px;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
        #content{
            .row{
                .accordion{
                    .checkout-process{
                        .checkout-step{
                            width: 100%;
                            @include media-breakpoint-up(lg){
                                max-width: 700px;
                                margin: 0;
                            }
                            .card-header{
                                padding: 0;
                                border: none;
                                background-color: transparent;
                                .step-title{
                                    margin: 0;                                    
                                    img{
                                        display: none;
                                    }
                                    span{
                                        font-family: 'Prompt';
                                        font-size: 19px;
                                        color: #000;
                                        &.step-number{
                                            margin-right: 5px;
                                        }
                                        @include media-breakpoint-up(lg){
                                            font-size: 22px;
                                            line-height: 36px;
                                        }
                                        @include media-breakpoint-up(xl){
                                            font-size: 25px;
                                            line-height: 30px;
                                        }
                                        &.step-text{
                                            @include media-breakpoint-up(xl){
                                                max-width: 65%;
                                                white-space: nowrap;
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }
                            }
                            .collapse{
                                .content{
                                    padding: 0 0 10px;
                                    /* ETAPE 1 - ONGLET */
                                    .nav-tabs{
                                        margin-top: 6px;
                                        border-bottom: none;
                                        justify-content: start;
                                        .nav-item{
                                            margin: 0;
                                            .nav-link{
                                                padding: 0;
                                                border: none;
                                                font-size: 18px;
                                                font-weight: 500;
                                                color: $gray-disabled;
                                                line-height: 58px;
                                                &.active{
                                                    font-weight: 600;
                                                    color: #000;
                                                }
                                            }
                                            &:first-child{
                                                .nav-link{
                                                    padding-right: 40px;
                                                    position: relative;
                                                    &:after{
                                                        content: '';
                                                        display: block;
                                                        width: 1px;
                                                        height: 31.5px;
                                                        background-color: $gray-disabled;
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        bottom: 0;
                                                        margin: auto;
                                                    }
                                                }
                                            }
                                            &:last-child{
                                                .nav-link{
                                                    padding-left: 40px;
                                                }
                                            }
                                        }
                                    }
                                    .tab-content{
                                        padding-top: 10px;
                                        .checkout-form{
                                            form{
                                                margin: 0;
                                                width: 100%;
                                                max-width: 643px;
                                            }
                                        }
                                    }

                                    /* ETAPE 1 ET 2 - FORMULAIRE */
                                    > p{
                                        clear: left;
                                        font-size: 15px;
                                        margin: 15px auto;
                                    }
                                    form{                                        
                                        p{
                                            font-size: 12px;
                                            line-height: 14px;
                                            margin-bottom: 24px;
                                            @include media-breakpoint-up(lg){
                                                font-size: 13px;
                                                line-height: 15px;
                                            }
                                        }
                                        .form-fields{
                                            .form-group{
                                                margin-bottom: 22px;
                                                label{ 
                                                    font-size: 14px;
                                                    line-height: 16px;
                                                    color: #000;
                                                    letter-spacing: 0.03em;                                                           
                                                    &:not(.custom-control-label){
                                                        margin-bottom: 11px;
                                                    }
                                                    span{
                                                        font-size: 14px;
                                                        line-height: 16.44px;
                                                        color: #000;
                                                        letter-spacing: 0.03em;
                                                    }
                                                }
                                                select,
                                                .form-control{
                                                    font-size: 13px;
                                                    color: $primary;
                                                    width: 100%;
                                                    height: 45px;
                                                    box-shadow: none;
                                                    background-color: $gray-bgfield;
                                                    border: 1px solid $tertiary;
                                                    @include media-breakpoint-up(lg){
                                                        font-size: 15px;
                                                    }
                                                }
                                                .input-group{
                                                    position: relative;
                                                    .show-hide{
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        display: flex;
                                                        align-items: center;

                                                        .btn{
                                                            padding: 0;
                                                            width: 90px;
                                                            height: 45px;
                                                            align-items: center;
                                                            justify-content: center;
                                                            background-color: $tertiary-light;
                                                            border: 1px solid $tertiary;
                                                        }
                                                    }
                                                }
                                                .required-fields-notice{
                                                    font-size: 13px;
                                                    color: #000;
                                                }
                                                &.form-group_forgot-password{
                                                    margin-top: -10px;
                                                    margin-bottom: 35px;
                                                    .forgot-password{
                                                        text-align: right;
                                                    }
                                                }
                                                &.form-birthday{
                                                    margin-bottom: 40px;
                                                }
                                            }
                                            .professional-form-groups{
                                                display: none;
                                            }
                                            .form-group:has(.professional){
                                                &:has(.custom-control-input:checked){
                                                    & + .professional-form-groups{
                                                        display: block;
                                                    }
                                                }
                                            }
                                            .same-address{
                                                margin-bottom: 15px;
                                                .custom-control-label{
                                                    line-height: 1;
                                                }
                                            }
                                        }
                                        .subtitle-address{
                                            clear: left;
                                            margin-top: 25px;
                                        }
                                    }
                                    .custom-checkbox,
                                    .custom-radio {
                                        label:before {
                                            border: 1px solid $tertiary;
                                        }
                                    }
                                    .invoice__address{
                                        margin-top: 25px;
                                    }
                                    .address-selector{
                                        .address-item{
                                            .card{
                                                min-height: 100%;
                                                .card-header{
                                                    padding-top: 15px;
                                                    .custom-control{
                                                        .custom-control-label{
                                                            &:before{
                                                                top: 0;
                                                            }
                                                            &:after{
                                                                top: 5px;
                                                            }
                                                        }
                                                    }
                                                }
                                                .card-footer{
                                                    @include media-breakpoint-up(lg){
                                                        display: flex;
                                                        justify-content: space-between;
                                                    }
                                                    a{
                                                        font-size: 13px;
                                                        @include media-breakpoint-down(lg){
                                                            margin-right: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                            &.selected{
                                                .card{
                                                    border-color: $yellow;
                                                }
                                            }
                                        }
                                        .card--address{
                                            background-color: $paper;
                                            &:hover{
                                                background-color: $yellow;
                                                i,
                                                span{
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                    .form-footer{
                                        .btn{
                                            font-weight: 600;
                                            font-size: 16px;
                                            color: #fff;
                                            display: block;
                                            width: 100%;
                                            padding: 0 15px;
                                            line-height: 50px;
                                            text-align: center;
                                            background-color: $tertiary-light;
                                            &:hover{
                                                background-color: $tertiary !important;
                                            }
                                            @include media-breakpoint-up(xl){
                                                font-size: 18px;
                                                line-height: 58px;
                                            }                                                
                                            &.js-cancel-address{
                                                margin-top: 15px;
                                            }
                                        }
                                    }

                                    /* ETAPE 3 - POINT DE RETRAIT */
                                    .delivery-options-list{
                                        margin-top: 25px;
                                        form{
                                            .form-fields{
                                                .delivery-options{
                                                    .delivery-option{
                                                        transition: background-color ease-in-out 300ms;
                                                        &:has(input:checked){
                                                            background-color: #f7dedc;
                                                        }
                                                        .row{
                                                            cursor: pointer;
                                                            align-items: center;
                                                            .col-sm-1{
                                                                .custom-control{
                                                                    padding: 0;
                                                                    .custom-control-label{
                                                                        position: absolute;
                                                                        top: 0;
                                                                        right: 0;
                                                                        bottom: 0;
                                                                        left: 0;
                                                                        display: block;
                                                                        &::before,
                                                                        &:after{
                                                                            top: 0;
                                                                            right: 0;
                                                                            bottom: 0;
                                                                            left: 0;
                                                                            margin: auto;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            label{
                                                                margin-bottom: 0;
                                                                .carrier-price{
                                                                    font-size: 16px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .bloc-emplacements{
                                                .be-header{
                                                    margin-bottom: 24px;
                                                    *{
                                                        font-family: 'Prompt';
                                                    }
                                                    .be-title{                                                            
                                                        font-weight: 400;
                                                        font-size: 16px;
                                                        line-height: 20px;
                                                        color: #000;
                                                        margin-top: 24px;
                                                        margin-bottom: 2px;
                                                        @include media-breakpoint-up(lg){
                                                            font-size: 23px;
                                                            line-height: 30px;
                                                        }
                                                    }
                                                    .be-desc{
                                                        font-weight: 400;
                                                        font-size: 13px;
                                                        line-height: 16px;
                                                        @include media-breakpoint-up(lg){
                                                            font-size: 17px;
                                                            line-height: 30px;
                                                        }
                                                    }
                                                }
                                                .be-list{
                                                    .be-item{                                                            
                                                        flex: 0 1 100%;                                                         
                                                        position: relative;   
                                                        .bei-check{
                                                            position: absolute;
                                                            top: 0;
                                                            left: 0;
                                                            opacity: 0;
                                                            &:checked{
                                                                + label{
                                                                    background-color: $pink;
                                                                }
                                                            }
                                                        }
                                                        label{
                                                            width: 100%;
                                                            display: block;
                                                            cursor: pointer;
                                                            border-top: none;
                                                            margin: 0 auto;
                                                            padding: 16px 30px;
                                                            background-color: $paper;
                                                            border-bottom: 1px solid $gray-border;
                                                            @include media-breakpoint-up(sm){                                                                
                                                                padding: 16px 26px;
                                                            }
                                                            &.selected{
                                                                background-color: $pink;
                                                            }
                                                            *{
                                                                color: #000;
                                                            }
                                                            .bei-date{
                                                                font-size: 17px;
                                                                font-weight: 600;
                                                                line-height: 20px;
                                                                margin-bottom: 10px;
                                                            }
                                                            .bei-info{
                                                                font-size: 15px;
                                                                line-height: 18px;
                                                                strong{
                                                                    font-weight: 600;
                                                                }
                                                            }
                                                        }
                                                        &:last-child{
                                                            label{
                                                                border-bottom: none;
                                                            }
                                                        }
                                                    }
                                                }
                                                .be-recap{
                                                    margin-top: 25px;
                                                    *{

                                                    }
                                                }
                                                .be-more{
                                                    text-align: center;
                                                    .load-more{
                                                        a{
                                                            font-weight: 600;
                                                            font-size: 15px;
                                                            line-height: 63px;
                                                            color: #000;
                                                            text-decoration: underline;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &#content-checkout-payment-step{
                                    /* ETAP 4 - OPTIONS DE PAIEMENT */
                                    .payment-options{
                                        margin-top: 25px;
                                        .payment-option{
                                            margin-bottom: 15px;
                                            padding: 12px 14px;
                                            background-color: $gray-bgfield;
                                            &:has(.custom-control-input:checked){
                                                background-color: $pink !important;
                                            }
                                            .custom-control{
                                                .custom-control-label{
                                                    font-size: 15px;
                                                    color: #000;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    &::before{
                                                        top: 0;
                                                    }
                                                    &:after{
                                                        top: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .additional-information{
                                        *{
                                            font-size: 15px;
                                            line-height: 20px;
                                        }
                                        p{
                                            margin: 5px auto;
                                        }
                                        .alert-info{
                                            background-color: $paper;
                                        }
                                    }
                                    .js-payment-binary{
                                        display: none;
                                        .alert{
                                            display: none;
                                        }
                                        .continue{
                                            font-weight: 600;
                                            font-size: 16px;
                                            color: #fff;
                                            display: block;
                                            width: 100%;
                                            padding: 0 15px;
                                            line-height: 50px;
                                            text-align: center;
                                            background-color: $tertiary !important;
                                            &[disabled="disabled"]{
                                                background-color: $tertiary-light;
                                            }
                                        }
                                    }
                                    #conditions-to-approve{
                                        .custom-control{
                                            .custom-control-label{
                                                &::before{
                                                    top: 0;
                                                }
                                                &:after{
                                                    top: 5px;
                                                }
                                                font-size: 14px;
                                                @include media-breakpoint-up(lg){
                                                    font-size: 15px;
                                                    line-height: 18px;
                                                }
                                                a{
                                                    font-size: 14px;
                                                    @include media-breakpoint-up(lg){
                                                        font-size: 15px;
                                                        line-height: 18px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .product-reas{
                                        margin-top: 32px;
                                        .pr-item{
                                            a{
                                                img{
                                                    transform: translateY(-2px);
                                                }
                                            }
                                        }
                                    }
                                    .form-footer{
                                        .continue{
                                            background-color: $tertiary !important;
                                            &[disabled="disabled"]{
                                                background-color: $tertiary-light;
                                            }
                                            @include media-breakpoint-up(xl){
                                                font-size: 18px;
                                                line-height: 58px;
                                            }
                                        }
                                        .notice{
                                            font-size: 13px;
                                            line-height: 50px;
                                            @include media-breakpoint-up(xl){
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }                            
                            &.-reachable{
                                .card-header{
                                    .step-title{
                                        .step-edit{     
                                            margin-top: 10px;                                       
                                            background-color: transparent;
                                            @include media-breakpoint-up(lg){
                                                float: right;
                                                margin-top: 5px; 
                                                align-items: center;
                                            }
                                            svg{
                                                margin-right: 8px;
                                                @include media-breakpoint-up(lg){
                                                    width: 14px;
                                                    height: 14px;
                                                    margin-right: 6px;
                                                }
                                                path{
                                                    transition: fill ease-in-out 300ms;
                                                }
                                            }
                                            span{
                                                font-size: 15px;
                                                @include media-breakpoint-up(lg){
                                                    font-size: 13px;
                                                }
                                            }
                                            &:hover{
                                                background-color: transparent !important;
                                                svg{
                                                    path{
                                                        fill: $tertiary;
                                                    }
                                                }
                                                span{
                                                    color: $tertiary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.-current,
                            &.-unreachable{
                                &:not(#checkout-personal-information-step){
                                    margin-top: 20px;
                                    padding-top: 20px;
                                    border-top: 1px solid $gray-disabled;
                                }
                            }
                            &.-complete{
                                &:not(#checkout-personal-information-step){
                                    margin-top: 20px;
                                    padding-top: 20px;
                                    border-top: 1px solid $gray-disabled;
                                }
                                .card-header{
                                    background-color: transparent;
                                    .step-title{
                                        img{
                                            float: left;
                                            display: block;
                                            margin-right: 13px;
                                            margin-bottom: 35px;
                                            @include media-breakpoint-up(lg){
                                                float: none;
                                                margin-right: 7px;
                                                margin-bottom: 0;
                                                display: inline-block;
                                            }
                                        }
                                        span{
                                            color: $gray-stepdone;
                                        }
                                        .step-edit{     
                                            padding: 0;
                                            margin-top: 10px;                                       
                                            background-color: transparent;
                                            @include media-breakpoint-up(lg){
                                                float: right;
                                                margin-top: 5px; 
                                                align-items: center;
                                            }
                                            svg{
                                                margin-right: 8px;
                                                @include media-breakpoint-up(lg){
                                                    width: 14px;
                                                    height: 14px;
                                                    margin-right: 6px;
                                                }
                                                path{
                                                    transition: fill ease-in-out 300ms;
                                                }
                                            }
                                            span{
                                                font-size: 15px;
                                                @include media-breakpoint-up(lg){
                                                    font-size: 13px;
                                                }
                                            }
                                            &:hover{
                                                background-color: transparent !important;
                                                svg{
                                                    path{
                                                        fill: $tertiary;
                                                    }
                                                }
                                                span{
                                                    color: $tertiary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .order-checkout{
                    padding: 0;
                    .card{
                        border: none;
                        padding: 32px 37px 10px;
                        background-color: $paper;
                        .card-body{
                            padding: 0;
                            .cart-summary-products{
                                #cart-summary-product-list{
                                    .media-list{
                                        .media-list__item{
                                            margin-bottom: 44px;
                                            a{
                                                display: block;
                                                margin-right: 31px;                                                
                                            }
                                            .media-body{
                                                *{
                                                    font-size: 13px;
                                                    line-height: 18px;
                                                    color: #000;
                                                    text-align: left;
                                                }
                                                .product-name{
                                                    font-size: 15px;
                                                    font-weight: 600;
                                                    line-height: 18px;    
                                                    margin-bottom: 7px;                                                
                                                }
                                                .product-line__qty{
                                                    margin-bottom: 15px;
                                                }
                                            }
                                            &:last-child{
                                                margin-bottom: 31px;
                                            }
                                        }
                                    }
                                }
                            }
                            .cart-qty{
                                font-size: 15px;
                                font-weight: 700;
                                color: #000;
                                line-height: 54px;
                                border-top: 1px solid #575757;
                            }
                            .cart-summary-line{
                                height: 55px;
                                margin: 0 auto;
                                align-items: center;
                                border-top: 1px solid #CBCBCB;
                                span{
                                    margin: 0;
                                }
                            }
                            .block-promo{
                                *{
                                    font-size: 15px;
                                }
                                .cart-voucher{
                                    .promo-code-button{
                                        .collapse-button{
                                            .redux-icon{
                                                .redux-add{
                                                    margin-right: 8px;
                                                }
                                                svg{
                                                    transition: transform ease-in-out 300ms;
                                                }
                                            }
                                            &[aria-expanded="true"]{
                                                display: flex;
                                                .redux-icon{
                                                    svg{
                                                        transform: rotate(45deg);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .promo-code{
                                        margin: 0;
                                        .promo-code__content{
                                            form{
                                                .input-group{
                                                    .input-group-append{
                                                        .btn{
                                                            font-size: 15px;
                                                            padding: 0 10px;
                                                            display: flex;
                                                            align-items: center;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }                            
                        }
                        .card-footer{
                            padding: 0;
                            background: transparent;
                            .cart-summary-totals{
                                .cart-summary-line{
                                    height: 54px;
                                    align-items: center;
                                    span{
                                        font-size: 15px;
                                        font-weight: 700;
                                        margin-bottom: 0; 
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

