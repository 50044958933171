#header {
  background-color: $paper;
  .header-top {
    padding-top: 15px;
    padding-bottom: 15px;
    @include media-breakpoint-up(lg) {
      min-height: 170px;
    }
    .header-left-part {
      width: 25%;
      max-width: 25%;
      #menu-icon {
        background: none;
        background: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        position: relative;
        z-index: 1060;
        svg {
          margin-bottom: 4px;
          transition: all 0.5s ease-out;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:hover {
          background: none!important;
        }
        &.cross {
          /* Adding transform to the X */
          transform: rotate(180deg);
          svg:nth-child(1) {
            transform: rotate(45deg) translate(5px, 5px);
          }
          svg:nth-child(2) {
            opacity: 0;
          }
          svg:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -5px);
          }
        }
        &:focus {
          box-shadow: none;
        }
      }
      .header__search {
        #search_widget {
          max-width: 100%;
          margin: 0;
          input {
            border: none;
            background: none;
            box-shadow: none;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            &::placeholder {
              color: black;
            }
          }
          button {
            background: none;
            box-shadow: none;
            padding: 0;
            &:hover {
              background-color: transparent!important;
            }
          }
        }
      }
    }
    .header-center-part {
      width: 45%;
      max-width: 45%;
      text-align: center;
      .header__logo {
        .logo {
          max-width: 65px;
          @include media-breakpoint-up(lg){
            max-width: 147px;
          }
        }
      }
    }
    .header-right-part {
      width: 25%;
      max-width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      #mobile-search--btn {
        margin-right: 35px;
      }
      .header__right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-direction: row-reverse;
        .blockcart {
          position: relative;
          .shopping-cart {
            .cart-products-count {
              background-color: $secondary;
              position: absolute;
              bottom: -2px;
              left: -8px;
              padding: 2.5px 4.5px 1px 4px;
              border-radius: 15px;
              font-family: 'Poppins';
              font-weight: 500;
              font-size: 10px;
              line-height: 10px;
              color: $paper;
            }
            svg {
              height: 23px;
              width: auto;
              @include media-breakpoint-up(lg){
                height: 40px;
              }
            }
            .cart-text {
              position: relative;
              top: 5px;
              left: 8px;
              margin-right: 10px;
              .label {
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.05em;
                color: #000000;
                margin-bottom: 0;
              }
              .price {
                font-family: 'Poppins';
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.05em;
                font-weight: 400;
              }
            }
          }
        }
        .user-info {
          margin-left: 24px;
          svg {
            height: 23px;
            width: auto;
            @include media-breakpoint-up(lg){
              height: 40px;
            }
          }
          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.05em;
            color: #000000;
            margin-bottom: 0;
          }
          @include media-breakpoint-up(xl){
            margin-left: 75px;
          }
        }
      }
    }
  }
  #mobile-search {
    #mobile-search--input {
      background-color: #F3F3F3;
      #search_widget {
        input {
          border: none;
          background: none;
          box-shadow: none;
          &::placeholder {
            color: black;
          }
        }
        .btn {
          background: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }
  }
}
//Cancel fade effect on mobile header
.modal-backdrop {
  opacity: 0!important;
}