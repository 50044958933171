.block-reassurances {
    margin-bottom: 50px;
    margin-right: -17.5px;
    margin-left: -17.5px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 130px;
        flex-wrap: nowrap!important;
    }
    .reassurance {
        width: calc(50% - 35px);
        margin-inline: 17.5px;
        margin-bottom: 35px;
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: fit-content;
            max-width: calc(25% - 92px);
            margin: auto;
            margin-bottom: 0;
        }
        @media screen and (min-width: 1300px) {
            max-width: fit-content;
        }
        &:first-child {
            img {
                min-height: 90px;
                max-width: 100%;
                margin-top: 25px;
            }
        }
        &:last-child {
            img {
                position: relative;
                bottom: -5px;
            }
        }
        img {
            object-fit: contain;
            max-height: 120px;
            width: auto;
            max-width: 100px;
            object-position: bottom;
        }
        .title {
            color: #000;
            text-align: center;
            font-size: 15px;
            letter-spacing: 0.45px;
            margin-top: 18px;
            @include media-breakpoint-up(lg) {
                font-size: 18px;
                letter-spacing: 0.54px;
            }
        }
    }
}