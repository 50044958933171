/* DESKTOP */
#_desktop_top_menu {
  background-color: $pink;
  position: relative;
  #top-menu {
    display: flex;
    justify-content: center;
    margin: 0;
    .menu__item--0 {
      .menu__item-header {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 61px;
        border-bottom: 3px solid transparent;
        margin-inline: 25px;
        &:hover {
          border-bottom: 3px solid $yellow;
        }
        span {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          color: #000;
          letter-spacing: 0.03em;
          text-align: center;
          @include media-breakpoint-up(xxl) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .menu-sub--0 {
        opacity: 0;
        height: 0;
        position: absolute;
        transition: all .2s ease;
        z-index: -1;
        width: 100%;
        height: 0px;
        left: 0;
        top: 61px;
        .menu-sub__content {
          .menu-sub__list--1 {
            padding: 25px 15px;
            max-width: 95%;
            justify-content: flex-start;
            margin: auto;
            background-color: #FFF;
            .wrapper {
              width: fit-content;
              min-width: 260px;
              * {
                width: fit-content;
              }
            }
            .no-sub-cat {
              .wrapper {
                min-width: 267px;
              }
            }
            @include media-breakpoint-up(xl) {
              max-width: 85%;
            }
            @include media-breakpoint-up(xxl) {
              max-width: 1530px;
              padding: 25px 10%;
              margin: auto;
            }
          }
        }
        >.menu-sub__content {
          width: 100vw;
          max-width: 100%;
          position: absolute;
        }
      }
      &:hover {
        .menu-sub--0 {
          z-index: 2;
          animation: expand 0.2s ease forwards;
        }
      }
    }
    .menu__item--1 {
      margin-bottom: 15px;
      align-items: center;
      .menu__item-header {
        min-height: auto;
        display: block;
        margin-bottom: 5px;
        width: fit-content;
        margin-inline: 0;
        span {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #333;
          padding-inline: 0;
          margin-bottom: 5px;
          text-align: left;
        }
      }
    }
    .menu__item--2 {
      .menu__item-header {
        border-bottom: none;
        &:hover {
          border-bottom: none;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 31px;
          color: #000;
          padding-bottom: 0px;
          margin-bottom: 0;
          text-align: left;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
}

/* MOBILE */
#mobile_top_menu_wrapper {
  .modal-dialog__offcanvas {
    width: 100%;
    max-width: 100%;
  }
  .modal-content {
    margin-top: 75px;
    box-shadow: none;
    background-color: $paper;
  }
  .modal-header {
    padding-inline: 30px;
    .close {
      margin: 0;
      padding: 0;
      opacity: 1;
    }
  }
  .modal-body {
    padding: 0;
    #top-menu {
      padding: 30px 40px 30px 30px;
      flex-direction: column;
      .menu__item--0 {
        .menu__item-header {
          min-height: fit-content;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          a {
            width: fit-content!important;
            &.menu__item-link--nosubmenu {
              width: 100%!important;
            }
          }
          span {
            font-family: 'Prompt';
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #000;
            letter-spacing: 0.03em;
          }
          svg {
            display: block;
            &[aria-expanded=true]{
              display: none;
            }
            &.icon-hide {
              display: none;
              &[aria-expanded=true]{
                display: block;
              }
            }
            &.arrow {
              &[aria-expanded=true]{
                display: block;
                transform: rotate(90deg);
              }
            }
          }
        }
        .menu-sub--0 {
          .menu-sub__list--1 {
            &:last-child {
              margin-bottom: 40px;
            }
          }
        }
      }
      .menu__item--1 {
        .menu__item-header {
          padding-left: 30px;
          padding-right: 5px;
          span {
            color: #333333;
          }
          a {
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .menu__item--2 {
        .menu__item-header {
          padding-left: 60px;
          opacity: 0.8;
          margin-bottom: 20px;
        }
        &:last-child {
          .menu__item-header {
            margin-bottom: 30px;
          }
        }
      }
      hr {
        margin: 0;
        border-top: 1px solid black;
      }
    }
    .js-top-menu-bottom {
      padding: 0px 30px 30px 30px;
      .menu-bottom-link {
        a {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-right: 15px;
            max-width: 28px;
            height: auto;
          }
          span {
            font-family: 'Prompt';
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #000;
            letter-spacing: 0.03em;
          }
        }
        &#_mobile_logout_link,
        &#_mobile_login_link {
          a {
            span {
              color: #FF0000;
            }
          }
        }
      }
    }
  }
}

/* Cancel shadow behind mobile menu */
.page-index .modal-backdrop.show {
  opacity: 0;
}

/* Expand menu animation */
@keyframes expand {
  from {
    opacity: 0;
    top: 0px;
  }
  to {
    opacity: 1;
    top: 61px;
  }
}