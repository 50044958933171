.page-wrapper--module-psgdpr-gdpr {
    border: none;
    background: none;
    .page-header {
        border: none;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }
    #content {
        padding: 0;
        .psgdprinfo17 {
            &:first-child {
                @include media-breakpoint-up(lg){
                    margin-top: 30px;
                }
            }
            h2 {
                @include media-breakpoint-up(sm){
                    font-size: 20px;
                }
            }
            p {
                padding-bottom: 15px;
                @include media-breakpoint-up(sm){
                    font-size: 18px;
                }
            }
            .btn {
                float: none;
                width: 100%;
                margin: 0;
                padding-inline: 10px;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-up(lg){
                    width: fit-content;
                    padding-inline: 30px;
                }
                &#exportDataToCsv {
                    margin-bottom: 5px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0px;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}