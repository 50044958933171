.page-cms {
    #who-are-we {
        padding-top: 25px;
        @include media-breakpoint-up(lg){
            padding-top: 80px;
            padding-bottom: 40px;
        }
        p {
            @include media-breakpoint-up(sm){
                font-size: unset;
            }
        }
        a.btn {
            max-height: 50px;
            min-height: 50px;
            @include media-breakpoint-up(lg){
                max-height: 58px;
                min-height: 58px;
            }
        }
        .image-side,
        .img-side {
            .image {
                background-repeat: no-repeat!important;
                background-size: cover!important;
                background-position: center!important;
            }
        }
        /* Bloc : Le concept Tiny Happy */
        #concept {
            margin-bottom: 50px;
            @include media-breakpoint-up(lg){
                margin-bottom: 90px;
            }
            h2 {
                margin-bottom: 5px;
                @include media-breakpoint-up(sm){
                    margin-bottom: 15px;
                }
                @include media-breakpoint-up(lg){
                    margin-bottom: 40px;
                }
            }
            .concept {
                .image-side {
                    margin-bottom: 10px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0;
                    }
                    .image {
                        min-height: 180px;
                        @include media-breakpoint-up(lg){
                            min-height: 100%;
                        }
                    }
                }
                .text-side {
                    p {
                        letter-spacing: 0.03em;
                        color: #000;
                        margin-top: 0;
                        padding-top: 15px;
                        @include media-breakpoint-up(lg){
                            font-size: 14px;
                            line-height: 20px;
                            &:first-child {
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
            .concept:nth-child(3),
            .concept:nth-child(4){
                margin-top: 25px;
                @include media-breakpoint-up(lg){
                    margin-top: 40px;
                }
            }
            .concept:nth-child(4) {
                .text-side {
                    margin-top: 20px;
                    .steps {
                        position: relative;
                        img {
                            max-height: 210px;
                            @include media-breakpoint-up(lg){
                                max-height: 320px;
                            }
                        }
                        p {
                            font-weight: 500;
                            position: absolute;
                            @include media-breakpoint-up(lg){
                                font-size: 17px;
                            }
                            &:nth-child(2){
                                left: 40px;
                                top: 0;
                                @include media-breakpoint-up(lg){
                                    left: 60px;
                                    top: 12px;
                                }
                            }
                            &:nth-child(3){
                                left: 170px;
                                top: 60px;
                                max-width: 200px;
                                text-align: left;
                                @include media-breakpoint-up(sm){
                                    max-width: none;
                                }
                                @media screen and (min-width: 824.9999px){
                                    top: 70px;
                                }
                                @include media-breakpoint-up(lg){
                                    left: 260px;
                                    top: 95px;
                                }
                                @include media-breakpoint-up(xl){
                                    top: 105px;
                                }
                                @include media-breakpoint-up(xxl){
                                    max-width: 310px;
                                    top: 115px;
                                }
                            }
                            &:nth-child(4){
                                left: 75px;
                                bottom: 0px;
                                @include media-breakpoint-up(lg){
                                    left: 115px;
                                    bottom: -5px;
                                }
                                @include media-breakpoint-up(xl){
                                    bottom: 0px;
                                }
                                @include media-breakpoint-up(xxl){
                                    padding-right: 31px;
                                    bottom: 15px;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 38px;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            @include media-breakpoint-up(lg){
                .concept:nth-child(2),
                .concept:nth-child(4) {
                    flex-direction: row-reverse;
                }
            }
        }
        /* Bloc : Nos valeurs */
        #values {
            padding-top: 25px;
            padding-bottom: 35px;
            @include media-breakpoint-up(lg){
                padding-top: 40px;
                padding-bottom: 60px;
            }
            .values {
                .img-side {
                    margin-bottom: 20px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0;
                    }
                    .image {
                        min-height: 220px;
                        @include media-breakpoint-up(lg){
                            min-height: 100%;
                        }
                    }
                }
                .text-side {
                    >p {
                        font-weight: 600;
                        letter-spacing: 0.03em;
                        color: #000;
                        margin-top: 0;
                        margin-bottom: 25px;
                        @include media-breakpoint-up(lg){
                            position: relative;
                            padding-left: 31px;
                        }
                    }
                    >a {
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        @include media-breakpoint-up(lg){
                            width: fit-content;
                            position: relative;
                            left: 31px;
                            margin-right: 31px;
                            padding-inline: 20px;
                        }
                    }
                    .values {
                        @include media-breakpoint-up(lg){
                            padding-top: 15px;
                            position: relative;
                            left: -67px;
                            margin-right: -67px;
                        }
                        .value {
                            display: flex;
                            margin-bottom: 30px;
                            .sticker {
                                display: flex;
                                align-items: center;
                                position: relative;
                                height: fit-content;
                                img {
                                    min-width: 46px;
                                    max-width: 46px;
                                    height: auto;
                                    position: relative;
                                    z-index: 5;
                                    @include media-breakpoint-up(lg){
                                        min-width: 80px;
                                        max-width: 80px;
                                    }
                                }
                                p {
                                    font-size: 20px;
                                    margin-top: 0;
                                    position: absolute;
                                    width: 100%;
                                    color: white;
                                    text-align: center;
                                    z-index: 10;
                                    left: -1px;
                                    top: 14px;
                                    @include media-breakpoint-up(sm){
                                        top: 10px;
                                    }
                                    @include media-breakpoint-up(lg){
                                        top: 25px;
                                        font-size: 28px;
                                        left: -3px;
                                    }
                                }
                            }
                            .text {
                                padding-left: 12px;
                                @include media-breakpoint-up(lg){
                                    padding-left: 20px;
                                }
                                p {
                                    margin-top: 5px;
                                    &:nth-child(1){
                                        margin-top: 0;
                                        font-weight: 700;
                                        font-size: 15px;
                                        line-height: 18px;
                                        @include media-breakpoint-up(sm){
                                            font-size: 18px;
                                            line-height: 21px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* Bloc : Notre histoire */
        #story {
            padding-top: 50px;
            @include media-breakpoint-up(lg){
                padding-top: 90px;
            }
            h2 {
                margin-bottom: 10px;
                @include media-breakpoint-up(lg){
                    margin-bottom: 40px;
                }
            }
            .story {
                @include media-breakpoint-up(lg){
                    flex-direction: row-reverse;
                }
                .image-side {
                    .image {
                        min-height: 230px;
                        max-height: 230px;
                        @include media-breakpoint-up(lg){
                            min-height: 100%;
                            max-height: 100%;
                        }
                    }
                }
                .text-side {
                    p {
                        font-size: 13px;
                        letter-spacing: 0.03em;
                        color: #000;
                        @include media-breakpoint-up(sm){
                            font-size: 16px;
                        }
                        @include media-breakpoint-up(lg){
                            font-size: 18px;
                            line-height: 21px;
                        }
                        &.subtitle {
                            font-size: 15px;
                            @include media-breakpoint-up(sm){
                                font-size: 18px;
                            }
                            @include media-breakpoint-up(lg){
                                margin-top: 0px;
                                font-size: 20px;
                                line-height: 26px;
                            }
                            strong {
                                font-weight: 600;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        /* Bloc : Citation */
        #quote {
            margin-top: 45px;
            margin-bottom: 45px;
            @include media-breakpoint-up(lg){
                margin-top: 70px;
                margin-bottom: 90px;
            }
            p {
                font-family: 'Prompt';
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.03em;
                color: $tertiary;
                margin-top: 0;
                @include media-breakpoint-up(sm){
                    font-size: 20px;
                    line-height: 23px;
                }
                @include media-breakpoint-up(xxl){
                    font-size: 35px;
                    line-height: 35px;
                }
            }
        }
        /* Bloc : Produits */
        #products,
        #partners,
        #manufacturers {
            .products,
            .partners,
            .manufacturers {
                .text-side {
                    @include media-breakpoint-up(lg){
                        padding-left: 55px;
                    }
                    p {
                        margin-top: 15px;
                        letter-spacing: 0.03em;
                        color: #000;
                        @include media-breakpoint-up(lg){
                            font-size: 14px;
                            line-height: 20px;
                        }
                        &.subtitle {
                            margin-top: 0;
                            font-family: 'Prompt';
                            font-size: 15px;
                            @include media-breakpoint-up(sm){
                                font-size: 18px;
                            }
                            @include media-breakpoint-up(lg){
                                font-size: 20px;
                                line-height: 26px;
                                margin-bottom: 30px;
                            }
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        min-height: 50px;
                        padding: inherit;
                        margin-top: 30px;
                        margin-bottom: 40px;
                        font-size: 17px;
                        line-height: 20px;
                        @include media-breakpoint-up(lg){
                            min-height: 58px;
                            align-items: center;
                            width: fit-content;
                            padding-inline: 70px;
                            margin-bottom: 0px;
                        }
                    }
                }
                .image-side {
                    margin-bottom: 20px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0px;
                    }
                    .image {
                        min-height: 230px;
                        max-height: 230px;
                        @include media-breakpoint-up(lg){
                            min-height: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }

        /* Bloc : Partenaires */
        @include media-breakpoint-up(lg){
            #partners {
                .partners {
                    flex-direction: row-reverse;
                    .text-side {
                        padding-right: 55px;
                        padding-left: 15px;
                        a {
                            padding-inline: 20px;
                        }
                    }
                }
            }
            #partners,
            #manufacturers {
                .partners,
                .manufacturers {
                    margin-top: 50px;
                }
            }
            /* Bloc : Fabricants */
            #manufacturers {
                .manufacturers {
                    .text-side {
                        p:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
.cms-id-14 {
    .page-header {
        display: none;
        @include media-breakpoint-up(lg){
            display: block;
        }
    }
}