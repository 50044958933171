.page-supplier,
.page-manufacturer {
    #content-wrapper {
        padding-inline: 0;
        .page-subtitle {
            margin-top: 10px;
        }
        .brands {
            margin-top: 25px;
            @include media-breakpoint-up(lg){
                margin-top: 60px;
                margin-bottom: 60px;
            }
            @include media-breakpoint-up(xxl){
                margin-right: -55px;
                margin-left: -55px;
            }
            .brand {
                margin-bottom: 25px;
                min-height: 100%;
                @include media-breakpoint-up(xxl){
                    margin-bottom: 60px;
                }
                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border: 1px solid #000;
                    background-color: $paper;
                    padding: 0px;
                    min-height: 100%;
                    @include media-breakpoint-up(xxl){
                        max-width: 250px;
                        margin: auto;
                    }
                    .brand-img {
                        min-height: 128px;
                        max-height: 128px;
                        background-repeat: no-repeat!important;
                        background-position: center!important;
                        background-size: contain;
                        padding-inline: 5px;
                        @include media-breakpoint-up(lg) {
                            min-height: 178px;
                            max-height: 178px;
                        }
                    }
                    .brand-infos {
                        @include media-breakpoint-up(md) {
                            padding-top: 10px;
                        }
                        @include media-breakpoint-up(lg) {
                            padding-inline: 15px;
                        }
                        .brand-name {
                            font-family: 'Prompt';
                            line-height: 30px;
                            text-align: center;
                            letter-spacing: 0.03em;
                            color: #3C3C3C;
                            margin: 0;
                            @include media-breakpoint-up(lg) {
                                font-size: 16px;
                                border-top: 1px solid #DADADA;
                                border-bottom: 1px solid #DADADA;
                            }
                        }
                        .brand-link,
                        .brand-text p {
                            font-weight: 200;
                            line-height: 10px;
                            font-size: 10px;
                            text-align: center;
                            letter-spacing: 0.03em;
                            color: #3C3C3C;
                            margin: 0;
                            padding-bottom: 10px;
                            @include media-breakpoint-up(lg){
                                font-size: 13px;
                                line-height: 13px;
                                padding-top: 10px;
                            }
                        }
                        .brand-link {
                            min-height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #3C3C3C;
                            text-decoration: underline;
                            font-weight: 300;
                            font-size: 11px;
                            border-top: 1px solid #DADADA;
                            padding: 0;
                            @include media-breakpoint-up(lg){
                                padding-top: 0px;
                            }
                        }
                    }
                    hr {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include media-breakpoint-up(lg){
                flex-direction: row;
                justify-content: center;
                margin-bottom: 120px;
            }
            .btn:first-child {
                margin-bottom: 15px;
                @include media-breakpoint-up(lg){
                    margin-bottom: 0;
                    margin-right: 18px;
                }
            }
            .btn {
                max-height: 48px;
                align-items: center;
                @include media-breakpoint-up(lg){
                    max-height: 58px;
                    min-height: 58px;
                }
            }
        }
    }
}
// NOS PARTENAIRES 
.page-supplier {
    #content-wrapper {
        .brands {
            .brand {
                .wrapper {
                    justify-content: flex-start;
                    .brand-infos {
                        padding-inline: 10px;
                        .brand-name {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 12px;
                            border-top: 1px solid #DADADA;
                            border-bottom: 1px solid #DADADA;
                            min-height: 40px;
                        }
                        .brand-text {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            p {
                                font-weight: 300;
                                line-height: 13px;
                                padding-bottom: 0;
                                @include media-breakpoint-up(lg){
                                    font-weight: 200;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}