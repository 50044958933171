.page-contact {
    .breadcrumb {
        padding-inline: 0;
    }
    #content-wrapper {
        background-color: #FFF;
        .page-wrapper {
            border: none;
            .page-content {
                background-color: #FFF;
                padding: 0;
                @include media-breakpoint-up(lg){
                    margin-bottom: 0;
                }
                h1 {
                    margin-bottom: 15px;
                    @include media-breakpoint-up(lg){
                        margin-top: 0;
                        margin-bottom: 45px;
                    }
                }
                .image-wrapper {
                    margin-bottom: 30px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 0;
                    }
                    .contact-img {
                        min-height: 165px;
                        height: 100%;
                        background-position: center!important;
                        background-repeat: no-repeat!important;
                        background-size: cover!important;
                        @include media-breakpoint-up(lg){
                            min-height: 485px;
                            height: 100%;
                        }
                    }
                }
                .form-wrapper {
                    .contact-form {
                        #contact-form {
                            @include media-breakpoint-up(lg){
                                width: 100%;
                                max-width: 550px;
                                margin-right: 0;
                            }
                            input,
                            select,
                            textarea {
                                background-color: #F3F3F3!important;
                                border: none;
                                box-shadow: none;
                                &::placeholder {
                                    font-size: 13px;
                                    line-height: 15px;
                                    letter-spacing: 0.03em;
                                    color: #4F4F4F!important;
                                    @include media-breakpoint-up(lg){
                                        font-size: 15px;
                                        line-height: 18px;
                                    }
                                }
                                &.btn {
                                    font-family: 'Raleway';
                                    font-weight: 600;
                                    font-size: 17px;
                                    line-height: 20px;
                                    width: 100%;
                                    justify-content: center;
                                    margin-top: 25px;
                                    background-color: $tertiary!important;
                                    &:hover {
                                        background-color: $tertiary-light!important;
                                    }
                                }
                            }
                            .required-fields-notice {
                                font-size: 11.5px;
                                line-height: 14px;
                                letter-spacing: 0.03em;
                            }
                        }
                    }
                }
                .another-request {
                    background-color: $paper;
                    padding-top: 25px;
                    padding-bottom: 45px;
                    padding-inline: 80px;
                    margin-top: 35px;
                    position: relative;
                    @include media-breakpoint-up(xl){
                        padding-inline: 120px;
                        margin-top: 75px;
                    }
                    @include media-breakpoint-up(xxl){
                        padding-inline: 210px;
                    }
                    >p {
                        font-family: 'Prompt';
                        font-size: 19px;
                        line-height: 23px;
                        letter-spacing: 0.03em;
                        color: #000;
                        margin-top: 0;
                        margin-bottom: 40px;
                    }
                    .blocks-wrapper {
                        width: fit-content;
                        margin: auto;
                        max-width: 100%;
                        word-break: break-word;
                        @include media-breakpoint-up(lg){
                            width: 100%;
                        }
                        .block {
                            margin-top: 0;
                            text-align: left;
                            margin-bottom: 40px;
                            width: fit-content;
                            .icon-wrapper {
                                min-width: 45px;
                                svg {
                                    margin-right: 15px;
                                    max-width: 30px;
                                }
                            }
                            .text,
                            .text span,
                            .text a {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 21px;
                                letter-spacing: 0.03em;
                                color: #000;
                                margin-top: 0;
                                span,
                                a {
                                    font-weight: 400;
                                }
                            }
                            &.tel-block {
                                .icon-wrapper {
                                    svg {
                                        max-height: 25px;
                                    }
                                }
                            }
                        }
                    }
                    .smiley-sticker {
                        &.first{
                            top: 6px;
                            right: 26px;
                            @include media-breakpoint-up(lg){
                                top: 3px;
                                right: 71px;
                            }
                        }
                        &.second{
                            top: 61px;
                            left: 23px;
                            @include media-breakpoint-up(lg){
                                top: 14px;
                                left: 4px;
                            }
                        }
                        &.third{
                            bottom: 96px;
                            right: 28px;
                            @include media-breakpoint-up(lg){
                                bottom: 32px;
                                right: 15px;
                            }
                        }
                        &.fourth{
                            bottom: 12px;
                            left: 13px;
                            @include media-breakpoint-up(lg){
                                bottom: 10px;
                                right: 87px;
                            }
                        }
                    }
                }
            }
        }
    }
    #footer {
        margin-top: 60px;
        @include media-breakpoint-up(lg){
            margin-top: 115px;
        }
    }
}