#module-prestablog-blog{
    #wrapper{
        /* TITRE ARTICLE */
        .singlepost-content{
            *{
                color: #000000;
            }
            .singlepost-title{
                font-family: 'Prompt';
                font-size: 19px;
                line-height: 22px;
                letter-spacing: 0.03em;
                margin-top: 0;
                margin-bottom: 5px;
                @include media-breakpoint-up(lg){
                    font-size: 28px;
                    line-height: 30px;
                    margin-bottom: 17px;
                }
            }
            time{
                display: block;
                margin-bottom: 20px;
                @include media-breakpoint-up(lg){
                    margin-bottom: 24px;
                }
                span{
                    font-size: 13px;
                    color: $primary;
                    line-height: 15px;
                    @include media-breakpoint-up(lg){
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }

        }

        /* PARTAGE ARTICLE */
        .singlepost-share{
            display: flex;
            align-items: center;
            margin-bottom: 17px;
            @include media-breakpoint-up(lg){
                margin-bottom: 41px;
            }
            *{
                color: #000;
            }
            span{
                font-family: 'Prompt';
                font-size: 15px;
                font-weight: 400;
                @include media-breakpoint-up(lg){
                    font-size: 18px;
                }
            }
            .share-buttons{
                display: flex;
                margin-bottom: 0;
                margin-left: 9px;
                align-items: center;
                svg{
                    margin-right: 14px;
                }
                .share-text{
                    display: none;
                }
            }
        }

        /* IMAGE A LA UNE ARTICLE */
        .singlepost-ban{
            margin-bottom: 16px;
            img{
                object-fit: cover;
                object-position: center;
                min-height: 300px;
                max-height: 300px;
                width: 100%;
            }
            @include media-breakpoint-up(lg){
                margin-bottom: 57px;
            }
        }

        /* CONTENU ARTICLE */
        #prestablogfont{
            margin-bottom: 55px;
            @include media-breakpoint-up(lg){
                max-width: 1001px;
                margin: 0 auto 134px;
            }
            *{
                color: inherit;
                font-size: 13px;
                line-height: 15px;
                @include media-breakpoint-up(lg){
                    font-size: 15px;
                    line-height: 18px;
                }
            }
            h2,
            h2 *{
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 12px;
                @include media-breakpoint-up(lg){
                    font-size: 19px;
                    line-height: 22px;
                    margin-bottom: 17px;
                }

            }
            h3,
            h3 *{
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 12px;
                @include media-breakpoint-up(lg){
                    font-size: 17px;
                    line-height: 20px;
                    margin-bottom: 17px;
                }

            }
            img{
                margin: 20px auto;
                @include media-breakpoint-up(lg){
                    margin: 40px auto;
                }
            }
        }

        /* SLIDER ARTICLE */
        .prestablog {
            padding: 0;
            .prestablog-hp-swiper {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                .swiper-wrapper {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;
                    height: 100%;
                    margin-inline: 0;
                    .swiper-slide {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        min-height: 100%;
                        padding-inline: 32.5px;
                    }
                }
                .swiper-navigation {
                    width: 100%;
                    justify-content: space-between;
                    position: absolute;
                    top: 48%;
                    left: 0;
                    .swiper-button {
                        position: relative;
                        &-prev {
                            right: 0;
                        }
                        &-next {
                            left: 0;
                        }
                        &-disabled {
                            svg path {
                                stroke: #0000004c;
                            }
                        }
                    }
                }
            }
            .see-all-btn {
                line-height: 20px;
                letter-spacing: 0.03em;
                margin: 5px auto auto;
                @include media-breakpoint-up(lg){
                    margin: 45px auto auto;
                }
            }
            .prestablog-hp-swiper .swiper-wrapper .swiper-slide .slide-wrapper,
            .prestablog-articles-mobile .article {
                width: 100%;
                height: 100%;
                border: 1px solid #DCDCDC;
                .article-img {
                    min-height: 155px;
                    max-height: 155px;
                    width: 100%;
                    background-repeat: no-repeat!important;
                    background-position: bottom!important;
                    background-size: cover!important;
                }
                .article-category {
                    position: absolute;
                    top: 0;
                    right: 32.5px;
                    font-size: 13px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.6px;
                    color: #000000;
                    background-color: $paper;
                    padding: 5px 15px;
                    width: fit-content;
                }
                .article-bottom {
                    padding: 15px;
                    p {
                        margin: 0;
                    }
                    .article-title {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.1px;
                        color: #000000;
                        text-align: left;
                    }
                    .article-intro {
                        font-size: 13px;
                        line-height: 15px;
                        margin-top: 8px;
                    }
                    .article-link {
                        padding-top: 15px;
                        .btn{
                            font-size: 15px;
                            font-weight: 400;
                            color: #000;
                            width: 132px;
                            display: block;
                            line-height: 32px;
                            text-align: center;
                            border: 1px solid #000;
                            padding: 0;
                            background-color: transparent;
                            transition: color ease-in-out 300ms, background-color ease-in-out 300ms, border-color ease-in-out 300ms;
                            &:hover{
                                color: #fff;
                                border-color: $tertiary;
                                background-color: $tertiary !important;
                            }
                        }
                    }
                }
            }
            .prestablog-articles-mobile {
                .article {
                    margin-bottom: 20px;
                    position: relative;
                    .article-img {
                        min-height: 160px;
                        max-height: 160px;
                    }
                    .article-category {
                        right: 0;
                    }
                    .article-title {
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .article-bottom {
                        padding: 10px;
                    }
                } 
                @include media-breakpoint-up(sm){
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .article {
                        max-width: 48%;
                    }
                }
            }
            @include media-breakpoint-up(lg){
                margin-bottom: 110px!important;
            }
        }
    }
}
