#index {
    #wrapper {
        #content-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

/* BEST SELLERS */
#index {
    .featured-products {
        margin-top: 38px;
        margin-bottom: 80px;
        overflow: hidden;
        @include media-breakpoint-up(xl) {
            margin-top: 90px;
            margin-bottom: 130px;
        }
        > .container {
            padding-right: 30px;
            @include media-breakpoint-up(sm) {
                padding-right: 60px;
            }
            @include media-breakpoint-up(xxl) {
                padding-right: 0;
            }
        } 
        h3 {
            font-size: 19px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 25px;
            color: $tertiary;
            @include media-breakpoint-up(lg) {
                font-size: 28px;
                margin-bottom: 45px;
            }
        }
        .products-swiper {
            margin-right: -32px;
            margin-left: 0;
            width: auto;
            overflow: unset;
            @include media-breakpoint-up(lg) {
                margin-right: -38px;
                margin-left: -38px;
            }
            @include media-breakpoint-up(xxl) {
                overflow: hidden;
            }
            .swiper-wrapper {
                align-items: stretch;
                .swiper-slide {
                    width: 53%;
                    min-width: 53%;
                    max-width: 53%;
                    margin-right: 32px;
                    margin-left: 0;
                    @include media-breakpoint-up(sm) {
                        width: 35%;
                        min-width: 35%;
                        max-width: 35%;
                    }
                    @include media-breakpoint-up(md) {
                        width: 25%;
                        min-width: 25%;
                        max-width: 25%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: calc(25% - 76px)!important;
                        min-width: calc(25% - 76px);
                        max-width: calc(25% - 76px);
                        margin-right: 38px;
                        margin-left: 38px;
                    }
                    .product-miniature {
                        margin-bottom: 0!important;
                        height: 100%;
                        a {
                            .card {
                                .card-body {
                                    .product-description {
                                        padding-bottom: 18px;
                                        .product-title {
                                            font-size: 16px;
                                            line-height: 19px;
                                            margin-top: 12px;
                                            padding-bottom: 0;
                                            @include media-breakpoint-up(xl) {
                                                font-size: 19px;
                                            }
                                        }
                                        .product-price-and-shipping {
                                            justify-content: flex-end;
                                            position: absolute;
                                            bottom: 0;
                                            right: 0;
                                            .price {
                                                font-size: 18px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .swiper-navigation {
            padding-inline: 0;
            @include media-breakpoint-up(xxl) {
                top: 37%;
            }
            .swiper-button-next {
                left: -30px;
                @include media-breakpoint-up(xxl) {
                    left: 40px;
                }
            }
            .swiper-button-prev {
                right: -30px;
                @include media-breakpoint-up(xxl) {
                    right: 40px;
                }
            }
        }
        .all-product-link {
            @include media-breakpoint-up(lg) {
                margin-top: 42px;
            }
            @include media-breakpoint-up(xl) {
                font-family: Raleway;
                font-size: 20px;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }
    }
}

/* CUSTOM TEXT */
#index {
    #custom-text {
        background: #d7d9da;
        padding-top: 35px;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            position: relative;
            padding-top: 0;
            overflow: hidden;
        }
        @include media-breakpoint-up(xxl) {
            margin-bottom: 90px;
        }
        > .container {
            @include media-breakpoint-down(sm) {
                padding-inline: 0;
            }
            @include media-breakpoint-up(md) {
                position: relative;
                min-height: 375px;
                max-height: 375px;
            }
            @include media-breakpoint-up(xl) {
                min-height: 575px;
                max-height: 575px;
            }
        }
        > p {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 375px;
                max-height: 375px;
                margin-top: 0;
            }
            @include media-breakpoint-up(xl) {
                min-height: 575px;
                max-height: 575px;
            }
            img {
                @include media-breakpoint-up(md) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    min-height: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    object-position: 50%;
                }
                @media screen and (min-width: 1920px) {
                    object-fit: none;
                    min-height: 575px;
                    max-height: 575px;
                } 
            }
        }
        .custom-text-content {
            @include media-breakpoint-up(md) {
                position: absolute;
                top: 50%;
                right: 0;
                max-width: 50%;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
            .content {
                position: relative;
                z-index: 2;
                padding-inline: 30px;
                @include media-breakpoint-up(md) {
                    padding-right: 60px;
                    padding-left: 0;
                    transform: translateY(-50%);
                }
                @include media-breakpoint-up(xxl) {
                    padding-right: 0;
                }
                p {
                    color: #000;
                    font-family: Prompt;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: left;
                    margin-top: 0;
                    @include media-breakpoint-up(xl) {
                        font-size: 32px;
                        line-height: 45px;
                    }
                    @include media-breakpoint-up(xxl) {
                        font-weight: 400;
                    }
                }
                .btns-wrapper {
                    @include media-breakpoint-up(sm) {
                        display: flex;
                        justify-content: flex-start;
                        align-items: stretch;
                        margin-top: 30px;
                    }
                    @include media-breakpoint-up(md) {
                        flex-wrap: wrap;
                        margin-top: 20px;
                    }
                    @include media-breakpoint-up(xl) {
                        flex-direction: column;
                        margin-top: 75px;
                    }
                    @include media-breakpoint-up(xxl) {
                        flex-direction: row;
                        align-items: stretch;
                    }
                }
                .btn {
                    color: #FCF8F8;
                    font-family: Raleway;
                    font-size: 15px;
                    line-height: normal;
                    letter-spacing: 0.45px;
                    padding: 20px;
                    min-width: 310px;
                    margin: auto;
                    margin-top: 10px;
                    justify-content: center;
                    @include media-breakpoint-up(sm) {
                        margin: unset;
                        margin-top: 0;
                        min-width: fit-content;
                    }
                    @include media-breakpoint-up(md) {
                        padding: 15px;
                        min-width: 310px;
                        margin-top: 10px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 20px;
                        letter-spacing: 0.6px;
                        align-items: center;
                        min-width: fit-content;
                        margin-top: 15px;
                    }
                    @include media-breakpoint-up(xxl) {
                        margin-top: 0;
                    }
                    &:first-child {
                        margin-top: 30px;
                        @include media-breakpoint-up(sm) {
                            margin-right: 10px;
                            margin-top: 0;
                        }
                        @include media-breakpoint-up(md) {
                            margin-right: 0;
                        }
                        @include media-breakpoint-up(xxl) {
                            margin-right: 16px;
                        }
                    }
                }
            }
            img {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
        img {
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 100%;
            min-height: 375px;
            max-height: 375px;
            object-fit: cover;
            object-position: 29%;
        }
    }
}

/* REASSURANCES */
#custom-reassurances {
    margin-right: -10px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: auto;
    @include media-breakpoint-up(xxl) {
        margin-right: 0;
        margin-bottom: 130px;
    }
    .reassurance {
        position: relative;
        min-width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
            min-width: calc(25% - 45px);
            max-width: calc(25% - 45px);
        }
        @include media-breakpoint-up(xxl) {
            min-width: fit-content;
            max-width: fit-content;
            margin: auto;
            &:first-child {
                min-width: 320px;
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        p {
            color: #000;
            text-align: center;
            font-size: 15px;
            line-height: normal;
            margin-top: 0;
            @include media-breakpoint-up(lg) {
                font-family: Prompt;
                font-size: 20px;
            }
            &:first-child {
                padding-bottom: 20px;
                @include media-breakpoint-up(lg) {
                    padding-bottom: 65px;
                }
                @include media-breakpoint-up(xxl) {
                    padding-bottom: 0;
                }
            }
            &:last-child {
                position: absolute;
                bottom: 0;
                width: 100%;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $tertiary;
                font-family: 'Prompt';
                @include media-breakpoint-up(lg) {
                    min-height: 65px;
                }
                @include media-breakpoint-up(xxl) {
                    position: relative;
                    min-height: fit-content;
                }
            }
        }
        img {
            transform: scale(0.8);
            @include media-breakpoint-up(lg) {
                transform: none;
            }
            @include media-breakpoint-up(xxl) {
                width: 200px;
            }
        }
    }
}

/* UNIVERS */
#index {
    #our-universes {
        margin-top: 40px;
        margin-bottom: 80px;
        @include media-breakpoint-up(xxl) {
            margin-bottom: 95px;
        }
        h3 {
            font-size: 19px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 25px;
            color: $tertiary;
            @include media-breakpoint-up(lg) {
                font-size: 28px;
                margin-bottom: 45px;
            }
        }
        .universes-swiper {
            .swiper-wrapper {
                display: flex;
                align-items: stretch;
                @include media-breakpoint-up(lg) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-right: -15px;
                    margin-left: -15px;
                }
                @include media-breakpoint-up(xxl) {
                    margin-right: -60px;
                    margin-left: -60px;
                }
                .swiper-slide {
                    width: 75%;
                    min-width: 75%;
                    max-width: 75%;
                    margin-right: 20px;
                    margin-left: 0;
                    @include media-breakpoint-up(sm) {
                        width: 45%;
                        min-width: 45%;
                        max-width: 45%;
                    }
                    @include media-breakpoint-up(md) {
                        width: 30%;
                        min-width: 30%;
                        max-width: 30%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: calc(33.3333% - 30px);
                        min-width: calc(33.3333% - 30px);
                        max-width: calc(33.3333% - 30px);
                        margin-right: 15px;
                        margin-left: 15px;
                        margin-bottom: 30px;
                    }
                    @include media-breakpoint-up(xxl) {
                        width: calc(33.3333% - 120px);
                        min-width: calc(33.3333% - 120px);
                        max-width: calc(33.3333% - 120px);
                        margin-right: 60px;
                        margin-left: 60px;
                        margin-bottom: 35px;
                    }
                    img {
                        object-fit: cover;
                        width: 100%;
                        min-height: 260px;
                        @include media-breakpoint-up(lg) {
                            min-height: 100%;
                            width: auto;
                        }
                    }
                    p {
                        color: #2F2F2F;
                        font-size: 20px;
                        line-height: 20px;
                        @include media-breakpoint-up(lg) {
                            text-align: center;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

/* PRESTABLOG */
#index {
    .prestablog {
        margin-top: 40px;
        h3 {
            font-size: 19px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 25px;
            color: $tertiary;
            @include media-breakpoint-up(lg) {
                font-size: 28px;
                margin-bottom: 45px;
            }
        }
        .prestablog-hp-swiper {
            width: auto;
            height: 100%;
            overflow: hidden;
            position: relative;
            .swiper-wrapper {
                display: flex;
                flex-wrap: nowrap;
                width: auto;
                height: 100%;
                .swiper-slide {
                    min-width: calc(33.3333% - 40px);
                    max-width: calc(33.3333% - 40px);
                    margin-right: 20px;
                    margin-left: 20px;
                    @include media-breakpoint-up(lg) {
                        min-width: calc(33.3333% - 50px);
                        max-width: calc(33.3333% - 50px);
                        margin-right: 25px;
                        margin-left: 25px;
                    }
                    @include media-breakpoint-up(xl) {
                        min-width: calc(33.3333% - 70px);
                        max-width: calc(33.3333% - 70px);
                        margin-right: 35px;
                        margin-left: 35px;
                    }
                }
            }
            .swiper-navigation {
                width: 100%;
                justify-content: space-between;
                position: absolute;
                top: 48%;
                left: 0;
                .swiper-button {
                    position: relative;
                    &-prev {
                        right: 0;
                    }
                    &-next {
                        left: 0;
                    }
                    &-disabled {
                        svg path {
                            stroke: #0000004c;
                        }
                    }
                    svg {
                        @include media-breakpoint-up(xl) {
                            height: 40px;
                            width: auto;
                        }
                    }
                }
            }
        }
        .see-all-btn {
            line-height: normal;
            letter-spacing: 0.48px;
            margin: 0 auto auto;
            padding: 10px 15px;
            font-weight: 300;
            @include media-breakpoint-up(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(lg){
                margin: 45px auto auto;
            }
            @include media-breakpoint-up(xl){
                font-family: Raleway;
                font-size: 20px;
                letter-spacing: 0.6px;
                padding: 17px 38px;
                margin: 70px auto auto;
            }
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 110px!important;
        }
    }
}
#index .prestablog .prestablog-hp-swiper .swiper-wrapper .swiper-slide .slide-wrapper,
#index .prestablog .prestablog-articles-mobile .article {
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .article-img {
        object-fit: cover;
        min-height: 155px;
        max-height: 155px;
        width: 100%;
        @include media-breakpoint-up(xl) {
            min-height: 230px;
            max-height: 230px;
        }
    }
    .article-category {
        position: absolute;
        top: 0;
        right: 32.5px;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.6px;
        color: #000000;
        background-color: $paper;
        padding: 5px 15px;
        width: fit-content;
        @include media-breakpoint-up(md) {
            right: 0;
        }
    }
    .article-bottom {
        padding: 15px;
        border: 1px solid #DCDCDC;
        border-top: none;
        @include media-breakpoint-up(md) {
            position: relative;
            flex-grow: 1;
            padding-bottom: 80px;
        }
        @include media-breakpoint-up(xl) {
            padding: 25px 15px 55px 15px;
        }
        p {
            margin: 0;
        }
        .article-title {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #000000;
            text-align: left;
            @include media-breakpoint-up(xl) {
                margin-bottom: 20px;
            }
        }
        .article-intro {
            font-size: 13px;
            line-height: 15px;
            margin-top: 8px;
            @include media-breakpoint-up(lg) {
                margin-top: 15px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 20px;
            }
        }
        .article-link {
            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: 15px;
                left: 15px;
            }
            .btn {
                font-family: 'Raleway';
                font-size: 15px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #000000;
                background: none;
                border: 1px solid #000000;
                padding: 8px 20px;
                margin-top: 15px;
                &:hover {
                    background-color: #FFFFFF!important;
                }
            }
        }
    }
}
#index .prestablog .prestablog-articles-mobile {
    @include media-breakpoint-up(sm){
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .article {
        margin-bottom: 24px;
        position: relative;
        @include media-breakpoint-up(sm){
            max-width: calc(50% - 12px);
        }
        &:last-child {
            max-width: 100%;
        }
        .article-img {
            object-fit: cover;
            min-height: 160px;
            max-height: 160px;
            width: 100%;
        }
        .article-category {
            right: 0;
        }
        .article-title {
            font-size: 14px;
            line-height: 16px;
        }
        .article-bottom {
            padding: 10px;
        }
    } 
}

#index {
    #hp-description {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
        @include media-breakpoint-up(lg) {
            margin-top: 110px;
            margin-bottom: 100px;
        }
        #text {
            color: #000;
            @include media-breakpoint-up(xl) {
                font-size: 20px;
                line-height: 33px;
            }
        }
        #toggle-button {
            color: #000;
            font-family: Raleway;
            font-weight: 700;
            text-decoration-line: underline;
            background: none!important;
            padding: 0;
            margin: auto;
            margin-top: 10px;
            @include media-breakpoint-up(xl) {
                font-size: 20px;
                line-height: 33px;
            }
            &:hover {
                cursor: pointer;
            }
            &:focus {
                outline: none;
            }
        }
    }
}