.swiper-pagination-bullet-active circle {
  fill: #EF7747;
}

.homeslider {
  margin-bottom: 35px;
  padding-bottom: 0;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      box-sizing: content-box;
      transform: translate3d(0px, 0, 0);
      .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        .slide-img {
          object-fit: cover;
          object-position: center!important;
          min-height: 375px;
          max-height: 375px;
          height: 100%;
          @include media-breakpoint-up(xl) {
            min-height: 600px;
            max-height: 600px;
          }
          @media screen and (min-width: 2200px) {
            min-height: 25vw;
            max-height: 25vw;
          }
        }
        .caption {
          height: 100%;
          width: 100%;
          padding: 35px;
          background: none;
          color: #000;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          @include media-breakpoint-up(md) {
            max-width: 60%;
            margin-inline: 20%;
            padding-inline: 0;
            text-align: left;
          }
          h1 {
            margin-top: 0;
            margin-bottom: 32px;
            font-size: 20px;
            font-weight: 500;
            line-height: 35px;
            text-align: left;
            color: $tertiary;
            @include media-breakpoint-up(xl) {
              font-size: 30px;
            }
          }
          .btn {
            position: relative;
            z-index: 10;
            font-size: 15px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.45px;
            @include media-breakpoint-up(xl) {
              font-size: 20px;
              letter-spacing: 0.6px;
            }
          }
        }
      }
      .swiper-slide:nth-child(3) {
         .caption {
          button {
            background-color: $secondary;
            transition: background-color 0.5s;
            &:hover {
              background-color: $yellow!important;
            }
          }
        }
      }
    }
    .swiper-navigation {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      position: absolute;
      bottom: calc(50% - 16px);
      z-index: 1;
      padding-inline: 35px;
      @include media-breakpoint-up(md) {
        padding-inline: 5%;
        bottom: calc(50% - 26px);
      }
      .swiper-button svg {
        height: 32px;
        width: auto;
        @include media-breakpoint-up(md) {
          height: 52px;
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      z-index: 1;
      bottom: 5px;
      width: 100%;
      text-align: center;
      @include media-breakpoint-up(md) {
        bottom: 20px;
      }
      .swiper-pagination-bullet {
        height: 12px;
        width: auto;
        @include media-breakpoint-up(md) {
          height: 20px;
        }
        &:first-child {
          @include media-breakpoint-up(md) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}