#module-prestablog-blog{
    #wrapper{
        .bloc-header{
            .bloc-title{
                font-family: 'Prompt';
                font-size: 19px;
                color: #000000;
                line-height: 22px;
                letter-spacing: 0.03em;
                margin-top: 0;
                margin-bottom: 12px;
                @include media-breakpoint-up(lg){
                    font-size: 28px;
                    line-height: 30px; 
                    margin-bottom: 17px;
                }
            }
            .bloc-desc{
                p{
                    font-size: 13px;
                    color: $primary;
                    line-height: 15px;                
                    margin-bottom: 18px;
                    letter-spacing: 0.03em;
                }
                @include media-breakpoint-up(lg){
                    p{
                        font-size: 15px;
                        line-height: 18px; 
                        text-align: justify;
                        margin-bottom: 46px;
                    }
                }
            }
        }
        .blog-menu{
            @include media-breakpoint-up(lg){
                margin-bottom: 72px;
            }
            nav{
                ul{
                    display: flex;
                    margin: 0 auto;
                    overflow-x: auto;
                    align-items: center;
                    justify-content: flex-start;
                    @include media-breakpoint-up(lg){
                        justify-content: center;
                        gap: 25px;
                    }
                    @include media-breakpoint-down(lg){
                        padding-bottom: 34px;
                    }
                    li{
                        @include media-breakpoint-down(sm){
                            margin-right: 25px;
                        }
                        a{
                            display: block;
                            text-align: center;
                            white-space: nowrap;
                            img{
                                display: block;
                                margin: 0 auto 14px;
                            }
                            span{
                                font-family: 'Prompt';
                                font-weight: 400;
                                font-size: 14px;
                                margin: auto;
                                color: #000;    
                                display: block;
                                width: 153px;
                                line-height: 54px;
                                padding: 0 5px;
                                background-color: #f9f9f9;                                                   
                                transition: color ease-in-out 300ms, background-color ease-in-out 300ms;
                                background-image: url(../img/design/catmask.png); 
                                @include media-breakpoint-down(lg){
                                    font-size: 12px;
                                    width: 126px;
                                    line-height: 44px;
                                    padding: 0 3px;
                                    background-size: contain;
                                }
                            }
                            &:hover,
                            &.current{
                                span{
                                    color: #fff;
                                    background-color: #818181;
                                }
                            }  
                        }
                    }
                }
            }
        }
        .three_article{
            @include media-breakpoint-up(lg){
                margin-bottom: 67px;
            }
            *{
                color: #000;
            }
            .blog-grid{
                padding: 15px 10px;
                @include media-breakpoint-up(lg){
                    padding: 23px 25px;
                }
                .block_cont{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #DADADA;
                    background-color: #FFFFFF;
                    transition: box-shadow ease-in-out 600ms;                    
                    .block_top{
                        position: relative;
                        a{
                            display: block;
                            overflow: hidden;
                            img{
                                width: 100%;
                                transform: scale(1);
                                transition: transform ease-in-out 600ms;
                                object-fit: cover;
                                object-position: center;
                                min-height: 160px;
                                max-height: 160px;
                                @include media-breakpoint-up(lg) {
                                    min-height: 175px;
                                    max-height: 175px;
                                }
                            }
                        }
                        .post-cat {
                            font-size: 12.5px;
                            line-height: 34px;
                            background-color: $paper;
                            padding: 0 15px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: auto;
                            @include media-breakpoint-up(lg){
                                font-size: 13px;
                            }
                        }
                    }
                    .block_bas{
                        padding: 15px;
                        .post-title{
                            line-height: 1;
                            margin-bottom: 7px;
                            a{
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 18px;
                                transition: color ease-in-out 300ms;
                                &:hover{
                                    color: $tertiary;
                                }
                            }
                        }
                        .post-desc{
                            color: $primary;
                            font-size: 13px;
                            line-height: 15px;
                            text-align: justify;
                            margin: 0;
                        }
                    }
                    .post-more{
                        margin-top: auto;
                        padding: 0 15px 15px;
                        .seemore{
                            font-size: 15px;
                            font-weight: 400;
                            width: 132px;
                            display: block;
                            line-height: 32px;
                            text-align: center;
                            border: 1px solid #000;
                            transition: color ease-in-out 300ms, background-color ease-in-out 300ms, border-color ease-in-out 300ms;
                            &:hover{
                                color: #fff;
                                border-color: $tertiary;
                                background-color: $tertiary;
                            }
                        }
                    }
                    &:hover{
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.29);
                        .block_top{
                            a{
                                img{
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }
                }
            }
        }
        .prestablog_pagination{
            clear: both;
            text-align: center;
            @include media-breakpoint-up(lg){
                margin-bottom: 67px;
            }
            *{
                font-family: 'Prompt';
                font-size: 19px;
                color: #000;
                padding: 5px 10px;
                border: none !important;
                background-color: transparent;
                &.current{
                    border-bottom: 1px solid #000 !important;
                }
                @include media-breakpoint-up(lg){
                    font-size: 22px;
                }
            }
            :first-child{
                margin-right: 10px;
            }
            :last-child{
                margin-left: 10px;
            }

        }
        .alert{
            @include media-breakpoint-down(lg){
                margin-top: 25px;
            }
        }
    }
}