.page-authentication,
.page-password,
.page-identity,
.page-address,
.page-contact {
    #content-wrapper {
        background-color: $paper;
        @include media-breakpoint-up(lg){
            background-color: #FFF;
        }
    }
    .page-wrapper {
        border: none;
        background-color: $paper;
        @include media-breakpoint-up(lg){
            background-color: #FFF;
        }
        .page-header {
            border: none;
            background: none;
            h1 {
                margin-bottom: 20px;
                @include media-breakpoint-up(lg){
                    margin-bottom: 60px;
                }
            }
            .login-logout-panel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0.03em;
                color: #656565;
                font-weight: 500;
                @include media-breakpoint-up(sm){
                    justify-content: center;
                    a:first-child {
                        margin-right: 20px;
                    }
                    a:last-child {
                        margin-left: 20px;
                    }
                }
                .current {
                    color: #000;
                    font-weight: 600;
                }
            }
        }
        @include media-breakpoint-up(lg){
            .page-content {
                margin-bottom: 130px;
                padding-bottom: 0;
            }
            .no-account-yet,
            #login-form,
            #customer-form,
            .forgotten-password,
            #address-form {
                width: 100%;
                max-width: 550px;
                >p {
                    margin-top: 0;
                    margin-bottom: 30px;
                    font-family: 'Prompt';
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.03em;
                    color: #000;
                }
            }
            .no-account-yet {
                border-right: 1px solid #000;
                >p{
                    font-size: 25px;
                    line-height: 30px;
                }
                .create-account-link {
                    font-family: 'Raleway';
                    font-weight: 600;
                    line-height: 20px;
                }
            }
            #login-form {
                >p {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
        }
        #login-form,
        #customer-form,
        .forgotten-password,
        #address-form,
        #contact-form {
            .send-renew-password-link {
                font-size: 16px;
                line-height: 20px;
                color: #000;
                font-weight: 500;
                margin-top: 0;
                margin-bottom: 20px;
                @include media-breakpoint-up(lg){
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            .form-group {
                label,
                label span {
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.03em;
                    color: #000;
                    @include media-breakpoint-up(lg){
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
                input,
                select,
                textarea {
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.03em;
                    color: #4F4F4F!important;
                    background-color: #FFF!important;
                    border: none;
                    box-shadow: none;
                    min-height: 45px;
                    @include media-breakpoint-up(lg){
                        font-size: 15px;
                        line-height: 18px;
                        min-height: 55px;
                        background-color: #F3F3F3!important;
                    }
                }
                .forgot-password {
                    text-align: right;
                    a {
                        letter-spacing: 0.03em;
                        text-decoration-line: underline;
                        color: #000;
                        @include media-breakpoint-up(lg){
                            font-size: 15px;
                            line-height: 18px;
                            color: #4F4F4F;
                            text-decoration: none;
                        }
                    }
                }
                .gender label,
                .professional label {
                    line-height: 25px;
                }
                .required-fields-notice {
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.03em;
                    color: #000;
                }
                .custom-checkbox {
                    input:focus~.custom-control-label:before {
                        box-shadow: none;
                    }
                    label {
                        &::before {
                            top: 0;
                        }
                        &::after {
                            top: 5px;
                        }
                    }
                }
                .show-hide {
                    .btn {
                        min-height: 45px;
                        padding: 10px 15px;
                        align-items: center;
                        @include media-breakpoint-up(lg){
                            min-height: 55px;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
            .professional-form-groups {
                display: none;
            }
            .form-footer {
                .btn {
                    width: 100%;
                    justify-content: center;
                    @include media-breakpoint-up(lg){
                        margin-top: 30px;
                    }
                }
            }
            .reset-password-btn {
                padding: 10px;
                width: 100%;
                justify-content: center;
                @include media-breakpoint-up(lg){
                    min-height: 55px;
                    align-items: center;
                }
            }
            .forgotten-password-error {
                color: red;
            }
        }
        #login-form {
            .form-footer .btn {
                background-color: $tertiary-light;
            }
        }
    }
}
.page-identity,
.page-address {
    .breadcrumb {
        background-color: $paper;
    }
    #wrapper {
        @include media-breakpoint-up(lg){
            background-color: $paper;
        }
        #content-wrapper {
            background-color: #FFF;
            @include media-breakpoint-up(lg){
                background-color: $paper;
            }
            .page-wrapper {
                background-color: #FFF;
                @include media-breakpoint-up(lg){
                    background-color: $paper;
                    #customer-form,
                    #address-form {
                        padding: 40px 90px 70px 90px;
                        max-width: 850px;
                        background-color: #FFF;
                    }
                }
                .page-header {
                    h1 {
                        margin-bottom: 0;
                    }
                }
                .page-content {
                    @include media-breakpoint-up(lg){
                        margin-bottom: 55px;
                        padding-top: 40px;
                    }
                }
                #customer-form,
                #address-form {
                     .form-group input,
                     .form-group select {
                        background-color: #F3F3F3!important;
                    }
                }
            }
        }
    }
}
.page-address {
    .breadcrumb {
        padding-inline: 0;
    }
    .page-wrapper {
        .page-header {
            display: none;
            @include media-breakpoint-up(lg){
                display: block;
            }
        }
        #address-form {
            .h1 {
                margin-bottom: 20px;
            }
            .form-footer .btn {
                margin-top: 30px;
            }
        }
    }
}
.page-password {
    .page-header {
        padding: 0;
    }
    .page-content {
        padding: 0;
        padding-bottom: 30px;
    }
}